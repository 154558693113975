import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * Versions SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgVersions(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M12 4h-1L9 2H8L6 0H0v12h3v2h3v2h9V7zM9 3l1 1H9zM6 1l1 1H6zM3 11H1V1h4v1H3zm3 2H4V3h4v1H6zm8 2H7V5h4v2.99h3zm-2-8V5l2 2z" })));
}
