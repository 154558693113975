import './loadingPage.css';
import React, { useEffect, useState } from 'react';
import { ProgressLinear, Text } from '@itwin/itwinui-react';
import { t } from '../translation';
/**
 * Wrapper around ProgressLinear to display a loading page with optional text.
 * @param loadingPageProps LoadingPage props
 * @returns JSX.Element
 */
export function LoadingPage({ className, fromValue, fullscreen = false, loadingSubText = '', loadingText = t('loadingPage.loading'), toValue, value }) {
    const classes = `pw-loading-page ${fullscreen ? 'pw-loading-page-fullscreen' : ''} ${className !== null && className !== void 0 ? className : ''}`;
    const indeterminate = value === undefined && fromValue === undefined && toValue === undefined;
    const [progressValue, setProgressValue] = useState(fromValue !== null && fromValue !== void 0 ? fromValue : value);
    useEffect(() => {
        if (fromValue === undefined || toValue === undefined) {
            return;
        }
        const timeoutId = setInterval(() => {
            setProgressValue((current) => {
                if (current !== undefined && current < toValue) {
                    return current + 1;
                }
                return current;
            });
        }, 200);
        return () => {
            clearInterval(timeoutId);
        };
    }, [fromValue, toValue]);
    return (React.createElement("div", { "data-testid": "loading-page", className: classes },
        React.createElement("div", { className: "pw-loading-container" },
            React.createElement(Text, { variant: "title", className: "pw-loading-text" }, loadingText),
            React.createElement(ProgressLinear, { className: "progress-linear", role: "progressbar", labels: [loadingSubText, undefined], labelGroupProps: { className: 'loading-subtext' }, indeterminate: indeterminate, value: value !== null && value !== void 0 ? value : progressValue, isAnimated: true }))));
}
