/**
 * Parses a version string into a Version object
 * @param version Version string to parse
 * @returns Version object
 */
export function parseVersion(version) {
    const versionParts = version.split('.');
    return {
        major: parseVersionPart(versionParts[0]),
        minor: parseVersionPart(versionParts[1]),
        patch: parseVersionPart(versionParts[2])
    };
}
function parseVersionPart(part) {
    if (isNaN(Number(part))) {
        return 0;
    }
    return Number(part);
}
/**
 * Determines if version a is greater than version b
 * @param a Version to compare
 * @param b Version to be compared against
 * @param depth Depth of comparison (major, minor, patch)
 * @returns True if version a is greater than version b at the specified depth
 */
export function isVersionGreaterThan(a, b, depth = 'patch') {
    if (a.major > b.major) {
        return true;
    }
    if (a.major < b.major || depth == 'major') {
        return false;
    }
    if (a.minor > b.minor) {
        return true;
    }
    if (a.minor < b.minor || depth == 'minor') {
        return false;
    }
    return a.patch > b.patch;
}
