import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@itwin/itwinui-react';
import { htmlTextLinks, plainTextLinks } from '../../services/link';
import { t } from '../../services/translation';
export function CopyButton({ items, selectedApp, getLink }) {
    const [copyCoolingOff, setCopyCoolingOff] = useState(false);
    function copyLinkText() {
        if (copyCoolingOff) {
            return t('link.copied');
        }
        return t('link.copy');
    }
    function onCopyClick() {
        function onCopy(event) {
            var _a, _b;
            const plainLinks = plainTextLinks(itemLinks);
            (_a = event.clipboardData) === null || _a === void 0 ? void 0 : _a.setData('text/plain', plainLinks);
            const htmlLinks = htmlTextLinks(itemLinks);
            (_b = event.clipboardData) === null || _b === void 0 ? void 0 : _b.setData('text/html', htmlLinks);
            event.preventDefault();
        }
        document.addEventListener('copy', onCopy);
        document.execCommand('copy');
        document.removeEventListener('copy', onCopy);
        onLinkCopied();
    }
    function onLinkCopied() {
        setCopyCoolingOff(true);
        setTimeout(() => setCopyCoolingOff(false), 5000);
    }
    const itemLinks = useMemo(() => {
        const itemLinks = items.map((item) => ({
            item: item,
            link: getLink(item, selectedApp)
        }));
        return itemLinks;
    }, [getLink, items, selectedApp]);
    useEffect(() => {
        setCopyCoolingOff(false);
    }, [selectedApp]);
    return (React.createElement(Button, { styleType: "high-visibility", onClick: onCopyClick, "data-testid": "copy-link-button", className: "copy-link-button" }, copyLinkText()));
}
