import { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthorizationService, HttpService } from '@bentley/pw-api';
import { useBuddi } from '../buddi';
import { prdssEndpoint } from './prdssUrlBuilder';
/**
 * Retrieves and manages a PRDSS setting
 * @param prdssConfig Configuration for the PRDSS setting
 * @param prdssConfig.buddiRegionCode Region code for BUDDI
 * @param prdssConfig.contextId Context ID, if setting saved in context partition
 * @param prdssConfig.defaultValue Default value if not set; will set it PRDSS
 * @param prdssConfig.gprId Organization ID, if setting saved in organization partition
 * @param prdssConfig.iModelId iModel ID, if setting saved in iModel partition
 * @param prdssConfig.name Name of the setting
 * @param prdssConfig.namespace Namespace of the setting, if used
 * @param prdssConfig.partition Partition of the setting
 * @param prdssConfig.shared If using shared setting in context partition
 * @param prdssConfig.getOidcToken Async function to get OIDC token
 * @returns Object containing current PRDSS value for setting, get, set, and remove functions
 */
export function usePrdss({ buddiRegionCode, contextId, defaultValue, gprId, iModelId, name, namespace, partition, shared, getOidcToken }) {
    const productSettingsUrl = useBuddi('NewProductSettingsService.Url', buddiRegionCode);
    const [current, setCurrent] = useState(defaultValue);
    const httpService = useMemo(() => {
        if (!productSettingsUrl) {
            return undefined;
        }
        const httpService = new HttpService({
            authorization: new AuthorizationService({ getOidcToken }),
            baseUrl: productSettingsUrl
        });
        return httpService;
    }, [getOidcToken, productSettingsUrl]);
    const settingEndpoint = useMemo(() => {
        return prdssEndpoint({
            contextId,
            gprId,
            iModelId,
            name,
            namespace,
            partition,
            shared
        });
    }, [contextId, gprId, iModelId, name, namespace, partition, shared]);
    const get = useCallback(async () => {
        if (!httpService) {
            throw new Error('Prdss http service not defined');
        }
        const response = await httpService.get(settingEndpoint);
        if (response.status == 404) {
            return undefined;
        }
        if (!response.ok) {
            console.error('Error getting prdss value', response);
            throw response;
        }
        const instance = (await response.json());
        setCurrent(instance.properties);
        return instance.properties;
    }, [httpService, settingEndpoint]);
    const remove = useCallback(async () => {
        if (!httpService) {
            throw new Error('Prdss http service not defined');
        }
        const response = await httpService.delete(settingEndpoint);
        if (!response.ok) {
            console.error('Error removing prdss value', response);
            throw response;
        }
        setCurrent(undefined);
    }, [httpService, settingEndpoint]);
    const set = useCallback(async (value) => {
        if (!httpService) {
            throw new Error('Prdss http service not defined');
        }
        const body = JSON.stringify({
            name,
            namespace,
            properties: value
        });
        const response = await httpService.put(settingEndpoint, body);
        if (!response.ok) {
            console.error('Error setting prdss value', response);
            throw response;
        }
        setCurrent(value);
    }, [httpService, name, namespace, settingEndpoint]);
    useEffect(() => {
        if (!httpService) {
            return;
        }
        async function initializeValue() {
            const value = await get();
            if (typeof value == 'undefined') {
                await set(defaultValue);
            }
            else {
                setCurrent(value);
            }
        }
        void initializeValue();
        /* eslint-disable-next-line react-hooks/exhaustive-deps --
         * Cannot use defaultValue in dependency array because it may cause infinite loop on arrays or objects
         */
    }, [get, httpService, set]);
    const prdssManager = {
        current,
        remove,
        get,
        set
    };
    return prdssManager;
}
