import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { t } from '../translation';
import { ErrorPage } from './errorPage';
/**
 * Wrapper around ErrorBoundary to display a generic error page when a child
 * component throws an error.
 * @param props ErrorFallback props
 * @returns JSX.Element
 */
export function ErrorFallback({ children }) {
    return (React.createElement(ErrorBoundary, { fallbackRender: ({ error }) => {
            var _a, _b;
            return (React.createElement(ErrorPage, { errorType: "generic", fullscreen: true, error: error === null || error === void 0 ? void 0 : error.name, errorDescription: React.createElement("pre", null, (_b = (_a = error === null || error === void 0 ? void 0 : error.message) !== null && _a !== void 0 ? _a : error === null || error === void 0 ? void 0 : error.cause) !== null && _b !== void 0 ? _b : t('errorPage.errorOccurred')) }));
        } }, children));
}
