import React from 'react';
import { Label, MenuDivider, MenuExtraContent, MenuItem } from '@itwin/itwinui-react';
/**
 * Provides component for use in toolbar corresponding to the menuItemAction.
 * @internal
 */
export function menuItemActionToMenuItem(menuItemAction, closeMenu) {
    const { groupLabel, icon, itemTooltip, startGroup, submenuItems, title, isActive, ...menuItemProps } = menuItemAction;
    return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    React.createElement("div", { key: title, onClick: (e) => e.stopPropagation() },
        startGroup && React.createElement(MenuDivider, null),
        groupLabel && startGroup && (React.createElement(MenuExtraContent, null,
            React.createElement(Label, null, groupLabel))),
        React.createElement(MenuItem, { className: "pw-menu-item", ...menuItemProps, onClick: (submenuItems === null || submenuItems === void 0 ? void 0 : submenuItems.length)
                ? undefined
                : () => {
                    var _a;
                    closeMenu();
                    (_a = menuItemAction.onClick) === null || _a === void 0 ? void 0 : _a.call(menuItemAction);
                }, startIcon: icon, subMenuItems: submenuItems === null || submenuItems === void 0 ? void 0 : submenuItems.map((item) => menuItemActionToMenuItem(item, closeMenu)), sublabel: itemTooltip }, title)));
}
