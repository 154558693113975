import './errorPage.css';
import React from 'react';
import { Svg401, Svg403, Svg404, Svg500, Svg502, Svg503, SvgError } from '@itwin/itwinui-illustrations-react';
import { NonIdealState } from '@itwin/itwinui-react';
import { PWModalPrimaryButton, PWModalSecondaryButton } from '../modal/pwModalButton';
import { t } from '../translation';
/**
 * Wrapper around NonIdealState to display error messages with optional details
 * and action buttons.
 * @param props ErrorPage props
 * @returns JSX.Element
 */
export function ErrorPage({ error, errorDescription, errorDetails, errorType, fullscreen, primaryButton, secondaryButton }) {
    const svg = buildIcon(resolveErrorType(errorType));
    const heading = error !== null && error !== void 0 ? error : buildDefaultHeading(resolveErrorType(errorType));
    const description = errorDescription !== null && errorDescription !== void 0 ? errorDescription : buildDefaultDescription(resolveErrorType(errorType));
    const classes = fullscreen
        ? 'error-page error-page-fullscreen'
        : 'error-page';
    return (React.createElement("div", { className: classes, "data-testid": "error-page" },
        React.createElement(NonIdealState, { className: "non-ideal-state", svg: svg, heading: heading, description: description, actions: React.createElement(React.Fragment, null,
                errorDetails && React.createElement("pre", null, errorDetails),
                React.createElement("div", { className: "e-p-action-buttons" },
                    primaryButton && React.createElement(PWModalPrimaryButton, { ...primaryButton }),
                    secondaryButton && (React.createElement(PWModalSecondaryButton, { ...secondaryButton })))) })));
}
function resolveErrorType(errorType) {
    var _a;
    if (typeof errorType === 'object') {
        const status = (_a = errorType.status) !== null && _a !== void 0 ? _a : 0;
        if ([401, 403, 404, 500, 502, 503].includes(status)) {
            return status.toString();
        }
        return 'generic';
    }
    return errorType;
}
function buildIcon(errorType) {
    switch (errorType) {
        case '401':
            return React.createElement(Svg401, { "data-testid": "401-error-icon" });
        case '403':
            return React.createElement(Svg403, { "data-testid": "403-error-icon" });
        case '404':
            return React.createElement(Svg404, { "data-testid": "404-error-icon" });
        case '500':
            return React.createElement(Svg500, { "data-testid": "500-error-icon" });
        case '502':
            return React.createElement(Svg502, { "data-testid": "502-error-icon" });
        case '503':
            return React.createElement(Svg503, { "data-testid": "503-error-icon" });
        default:
            return React.createElement(SvgError, { "data-testid": "default-error-icon" });
    }
}
function buildDefaultHeading(errorType) {
    switch (errorType) {
        case '401':
        case '403':
            return t('errorPage.unauthorized');
        case '404':
            return t('errorPage.pageNotFound');
        case '500':
            return t('errorPage.internalError');
        case '502':
            return t('errorPage.badGateway');
        case '503':
            return t('errorPage.serviceUnavailable');
        default:
            return t('errorPage.error');
    }
}
function buildDefaultDescription(errorType) {
    switch (errorType) {
        case '401':
        case '403':
            return t('errorPage.notAuthorized');
        case '404':
            return t('errorPage.cannotFindPWProject');
        case '500':
        case '502':
            return t('errorPage.refresh');
        case '503':
            return t('errorPage.serviceUnavailableTryAgain');
        default:
            return t('errorPage.errorOccurred');
    }
}
