const imsAdminRoles = {
    admin: 'Account Admin',
    coAdmin: 'SITE_ADMINISTRATOR',
    projectManager: 'Project Manager'
};
/**
 * Check if user has IMS admin role
 * @param user Oidc user
 * @returns boolean User has IMS admin role, true if not associated with an org
 */
export function hasIMSAdminRole(user) {
    var _a;
    if (!user) {
        return false;
    }
    // Allow if user is not associated with any org
    if (!user.profile.org) {
        return true;
    }
    if (!((_a = user.profile) === null || _a === void 0 ? void 0 : _a.role)) {
        return false;
    }
    const userRoles = toArray(user.profile.role);
    return userRoles.some((role) => role == imsAdminRoles.admin ||
        role == imsAdminRoles.coAdmin ||
        role == imsAdminRoles.projectManager);
}
/**
 * Check if user has edit connection permission
 * @param user Oidc user
 * @param permissions RBAC permissions for user
 * @returns boolean User has edit connection permission
 */
export function hasEditConnectionPermission(user, permissions) {
    if (hasIMSAdminRole(user)) {
        return true;
    }
    const hasModifyConnectionsPermission = permissions.some(({ instanceId }) => instanceId == 'PSFEDCONNMODIFY');
    const hasManageSettingsPermission = permissions.some(({ instanceId }) => instanceId == '2752_PrdssCtxWrite');
    return hasModifyConnectionsPermission && hasManageSettingsPermission;
}
function toArray(value) {
    return Array.isArray(value) ? value : [value];
}
