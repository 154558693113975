import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * ChevronTop SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgChevronTop(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("rect", { x: "0", y: "0", width: "16", height: "2" }),
        React.createElement("path", { d: "M16 11.3l-1.4 1.4L8 6.1l-6.6 6.6L0 11.3l8-8" })));
}
