/**
 * Build a valid PRDSS endpoint URL based on provided configuration options
 * @param prdssEndpointOptions Configuration options for PRDSS
 * @param prdssEndpointOptions.contextId Optional context ID for context partition
 * @param prdssEndpointOptions.gprId GPRID (application ID)
 * @param prdssEndpointOptions.iModelId Optional iModel ID for context partition
 * @param prdssEndpointOptions.name Setting name
 * @param prdssEndpointOptions.namespace Optional namespace for setting name
 * @param prdssEndpointOptions.partition Partition type
 * @param prdssEndpointOptions.shared Shared setting flag
 * @returns PRDSS endpoint path without baseUrl
 */
export function prdssEndpoint({ contextId, gprId, iModelId, name, namespace, partition, shared }) {
    const applicationPath = buildApplicationPath(gprId);
    const partitionPath = buildPartitionPath(partition, contextId, iModelId);
    const settingPath = buildSettingPath(partition, shared);
    const namePath = buildNamePath(namespace, name);
    return `${applicationPath}${partitionPath}${settingPath}${namePath}`;
}
function buildApplicationPath(gprId) {
    if (!gprId) {
        return '';
    }
    return `Application/${String(gprId)}/`;
}
function buildPartitionPath(partition, contextId, iModelId) {
    const iModelPath = buildIModelPath(iModelId);
    if (partition == 'Context') {
        if (!contextId) {
            throw new Error('Prdss context partition requires contextId');
        }
        return `Context/${contextId}/${iModelPath}`;
    }
    if (partition == 'User' && contextId) {
        return `Context/${contextId}/${iModelPath}User/`;
    }
    return `${partition}/`;
}
function buildIModelPath(iModelId) {
    if (!iModelId) {
        return '';
    }
    return `iModel/${iModelId}/`;
}
function buildSettingPath(partition, shared) {
    if (shared) {
        if (partition != 'Context') {
            throw new Error('Shared settings are only supported for context partition');
        }
        return 'SharedSetting/';
    }
    return 'Setting/';
}
function buildNamePath(namespace, name) {
    if (!namespace) {
        return replaceInvalidCharacters(name);
    }
    return `${replaceInvalidCharacters(namespace)}/${replaceInvalidCharacters(name)}`;
}
function replaceInvalidCharacters(value) {
    return value.replace(/[/\\?]/g, '_');
}
