import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * ChevronBottom SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgChevronBottom(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M16 4.7l-1.4-1.4L8 9.9 1.4 3.3 0 4.7l8 8z" }),
        React.createElement("rect", { x: "0", y: "14", width: "16", height: "2" })));
}
