import type { ShareLinkQuery } from '../../hooks/useShareLink/reducer';

export function getLinkProps(): Partial<ShareLinkQuery> {
  return {
    datasource: parseQueryString('datasource'),
    workAreaId: parseQueryString('workAreaId'),
    objectId: parseQueryString('objectId'),
    objectType: parseQueryString('objectType') == 'folder' ? 'folder' : 'doc',
    projectId: parseQueryString('projectId'),
    objectName: parseQueryString('objectName')
  };
}

export function getMissingRequiredParams({
  datasource,
  objectId,
  objectType,
  objectName
}: Partial<ShareLinkQuery>): string[] {
  const missing = [];

  if (!datasource) {
    missing.push('datasource');
  }

  if (!objectId) {
    missing.push('objectId');
  }

  if (!objectType) {
    missing.push('objectType');
  }

  if (!objectName) {
    missing.push('objectName');
  }

  return missing;
}

function parseQueryString(searchParameter: string): string | undefined {
  const searchParameters = new URLSearchParams(location.search);
  return (
    searchParameters.get(searchParameter) ||
    searchParameters.get(searchParameter.toLowerCase()) ||
    undefined
  );
}
