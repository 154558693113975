export function isSavedSearch(item) {
    return item.className == 'SavedSearchDefinition';
}
export function asItem(item) {
    if (!isSavedSearch(item)) {
        return item;
    }
    return {
        Name: item.Name,
        className: 'SavedSearch'
    };
}
