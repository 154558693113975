import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * ProjectWise SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgProjectWise(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M14.22222,0H1.77778A1.77778,1.77778,0,0,0,0,1.77778V14.22222A1.77778,1.77778,0,0,0,1.77778,16H14.22222A1.77778,1.77778,0,0,0,16,14.22222V1.77778A1.77778,1.77778,0,0,0,14.22222,0Zm-8.56,3.77778L4.72,4.89778,9.31556,6.22222,8,7.88444,3.28,6.54222,2.30222,7.67111,1.49333,5.05778ZM2.02667,13.44l-.8-2.61333,1.44.38222L5.77778,7.45778l2.05333.58667L4.78222,11.76l1.44.4Zm8.31111-1.21778.94222-1.12L6.68444,9.77778,8,8.11556l4.68444,1.34222.97778-1.12889.80889,2.61333Zm2.99556-7.43111L10.22222,8.54222,8.16889,7.94667l3.04889-3.71556L9.77778,3.84l4.16-1.28.8,2.61333Z" })));
}
