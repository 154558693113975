import React from 'react';
import { Tooltip } from '@itwin/itwinui-react';
/**
 * Wrapper around Tooltip component to not display empty tooltips.
 * @param props Tooltip props
 * @returns JSX.Element
 */
export function PWTooltip(props) {
    const { content, children, 'aria-hidden': ariaHidden, ...rest } = props;
    if (!content) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement(Tooltip, { content: content, ...rest }, children));
}
