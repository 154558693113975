/**
 * Returns instances returned from a WSG query; properties are flattened into the instance
 * @param response HTTP response from a WSG query
 * @returns Instances returned from a WSG query
 * @throws Will throw original HTTP response if not ok; will throw error if found on WSG response
 */
export async function parseResponseInstances(response) {
    var _a, _b;
    if (!(response === null || response === void 0 ? void 0 : response.ok)) {
        throw response;
    }
    const wsgResponse = (await response.json());
    handleWSGErrorResponse(wsgResponse);
    const instances = (_b = (_a = wsgResponse.instances) === null || _a === void 0 ? void 0 : _a.map(flattenProperties)) !== null && _b !== void 0 ? _b : [];
    return instances;
}
/**
 * Returns changed instance returned from a WSG query; properties are flattened into the instance
 * @param response HTTP response from a WSG query
 * @returns Changed instance returned from a WSG query
 * @throws Will throw original HTTP response if not ok; will throw error if found on WSG response
 */
export async function parseResponseChangedInstance(response) {
    if (!(response === null || response === void 0 ? void 0 : response.ok)) {
        throw response;
    }
    const wsgResponse = (await response.json());
    handleWSGErrorResponse(wsgResponse);
    const instance = wsgResponse.changedInstance.instanceAfterChange;
    const flattenedInstance = flattenProperties(instance);
    return flattenedInstance;
}
/**
 * Returns instances related to first instance returned from a WSG query; properties are flattened into the instances
 * @param response HTTP response from a WSG query
 * @returns Instances related to first instance returned from a WSG query
 * @throws Will throw original HTTP response if not ok; will throw error if found on WSG response
 */
export async function parseResponseRelationshipInstances(response) {
    var _a;
    if (!(response === null || response === void 0 ? void 0 : response.ok)) {
        throw response;
    }
    const wsgResponse = (await response.json());
    handleWSGErrorResponse(wsgResponse);
    if (!((_a = wsgResponse.instances) === null || _a === void 0 ? void 0 : _a.length)) {
        return [];
    }
    const instance = wsgResponse.instances[0];
    const instances = parseInstanceRelationshipInstances(instance);
    return instances;
}
/**
 * Returns the first instance related to each instance returned from a WSG query; each properties are flattened into the instances
 * @param response HTTP response from a WSG query
 * @returns Instances related to all instances returned from a WSG query
 * @throws Will throw original HTTP response if not ok; will throw error if found on WSG response
 */
export async function parseMultiResponseRelationshipInstance(response) {
    var _a;
    if (!(response === null || response === void 0 ? void 0 : response.ok)) {
        throw response;
    }
    const wsgResponse = (await response.json());
    handleWSGErrorResponse(wsgResponse);
    if (!((_a = wsgResponse.instances) === null || _a === void 0 ? void 0 : _a.length)) {
        return [];
    }
    const multipleRawInstances = wsgResponse.instances;
    const instances = multipleRawInstances.map((rawInstance) => parseInstanceRelationshipInstances(rawInstance)[0]);
    return instances;
}
/**
 * Returns instances related to an instance; properties are flattened into the instances
 * @param instance Instance returned from a WSG query
 * @returns Instances related to an instance
 */
export function parseInstanceRelationshipInstances(instance) {
    var _a;
    if (!((_a = instance.relationshipInstances) === null || _a === void 0 ? void 0 : _a.length)) {
        return [];
    }
    const relatedInstances = instance.relationshipInstances
        .filter((relationshipInstance) => relationshipInstance.relatedInstance)
        .map((relationshipInstance) => parseRelatedInstance(instance, relationshipInstance));
    const instances = relatedInstances.map(flattenProperties);
    return instances;
}
/**
 * Merges properties into an instance so that properties are not nested
 * @param instance Instance returned from a WSG query
 * @returns Instance with properties merged into the instance
 */
export function flattenProperties(instance) {
    const { properties, ...rest } = instance;
    return { ...rest, ...properties };
}
function handleWSGErrorResponse(response) {
    if (response.errorDescription || response.errorId || response.errorMessage) {
        const errorData = JSON.stringify({
            errorDescription: response.errorDescription,
            errorId: response.errorId,
            errorMessage: response.errorMessage
        });
        throw new Error(errorData);
    }
}
function parseRelatedInstance(instance, relationshipInstance) {
    var _a, _b;
    const relatedInstance = relationshipInstance.relatedInstance;
    const reversedRelationshipInstance = reverseRelationshipInstance(instance, relationshipInstance);
    if (!((_a = relatedInstance.relationshipInstances) === null || _a === void 0 ? void 0 : _a.some(({ className }) => className == reversedRelationshipInstance.className))) {
        (_b = relatedInstance.relationshipInstances) !== null && _b !== void 0 ? _b : (relatedInstance.relationshipInstances = []);
        relatedInstance.relationshipInstances.push(reversedRelationshipInstance);
    }
    return relatedInstance;
}
function reverseRelationshipInstance(instance, relationshipInstance) {
    const { relationshipInstances, ...originalInstance } = instance;
    const { direction, ...rest } = relationshipInstance;
    return {
        ...rest,
        relatedInstance: originalInstance,
        direction: direction === 'forward' ? 'backward' : 'forward'
    };
}
