import { useEffect, useMemo, useState } from 'react';
import { featureMapValues } from './featureMapValues';
import { buildFeatureToggleClient } from './featureToggleClient';
import { lookupLDKey } from './ldKey';
/**
 * Initializes Bentley feature toggle client and returns feature flags.
 * @param featuresConfig Configuration for feature flags
 * @param featuresConfig.buddiRegionCode Region code, if not using feature toggle key
 * @param featuresConfig.dataAccessLevel Optional data access level, if not using feature toggle key
 * @param featuresConfig.featureToggleKey Feature toggle client key, if not using region code
 * @param featuresConfig.getSamlToken Async function to get SAML token
 * @returns Object containing feature flags, loading state, and error
 */
export function useFeatures({ buddiRegionCode, dataAccessLevel, featureToggleKey, getSamlToken }) {
    const [features, setFeatures] = useState();
    const [error, setError] = useState();
    useEffect(() => {
        if (!buddiRegionCode && !featureToggleKey) {
            setError(new Error('Missing feature toggle key.'));
            return;
        }
        const abortController = new AbortController();
        async function initializeClient() {
            try {
                const clientSideId = featureToggleKey !== null && featureToggleKey !== void 0 ? featureToggleKey : (await lookupLDKey(buddiRegionCode, dataAccessLevel));
                const client = await buildFeatureToggleClient(getSamlToken, clientSideId);
                const features = featureMapValues(client);
                if (!abortController.signal.aborted) {
                    setFeatures(features);
                }
            }
            catch (error) {
                setError(error);
            }
        }
        if (!features) {
            void initializeClient();
        }
        return () => {
            abortController.abort();
        };
    }, [
        buddiRegionCode,
        dataAccessLevel,
        featureToggleKey,
        getSamlToken,
        setFeatures,
        features
    ]);
    const featureFlagManager = useMemo(() => ({
        features: features,
        isLoading: !features,
        error: error
    }), [error, features]);
    return featureFlagManager;
}
