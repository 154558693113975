import { useCallback, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';
export function useSelectedApplication({ consumerApp, dataAccessLevel, disabledApplications, hiddenApplications }) {
    const defaultApp = useMemo(() => {
        if (consumerApp == 'Teams' || consumerApp == 'ProjectWise 365 in Teams') {
            return 'Teams';
        }
        if (dataAccessLevel == 'Datasource') {
            return 'WebView';
        }
        if ((disabledApplications === null || disabledApplications === void 0 ? void 0 : disabledApplications.includes('Web')) ||
            (hiddenApplications === null || hiddenApplications === void 0 ? void 0 : hiddenApplications.includes('Web'))) {
            return 'Explorer';
        }
        return 'Web';
    }, [consumerApp, dataAccessLevel, disabledApplications, hiddenApplications]);
    const [lastUsedApp, setLastUsedApp] = useLocalStorage('linkApplication', defaultApp);
    const selectedApp = useMemo(() => {
        if ((disabledApplications === null || disabledApplications === void 0 ? void 0 : disabledApplications.includes(lastUsedApp)) ||
            (hiddenApplications === null || hiddenApplications === void 0 ? void 0 : hiddenApplications.includes(lastUsedApp))) {
            return defaultApp;
        }
        return lastUsedApp;
    }, [defaultApp, disabledApplications, hiddenApplications, lastUsedApp]);
    const setSelectedApp = useCallback((application) => {
        setLastUsedApp(application);
    }, [setLastUsedApp]);
    return [selectedApp, setSelectedApp];
}
