/* eslint-disable import/no-unused-modules */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppRoutes } from './appRoutes';

if (window.location.pathname.startsWith('//')) {
  // Double slash fixes issue with PW Explorer routing
  window.location.pathname = window.location.pathname.substring(1);
} else {
  const container = document.getElementById('app');
  if (!container) {
    throw new Error('Could not find app container');
  }
  const root = createRoot(container);
  root.render(<AppRoutes />);
}
