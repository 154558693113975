import React from 'react';
import { SvgLogicalSetOverlay } from '../svg';
import { DocumentIcon } from './documentIcon';
/**
 * Icon for PWLogicalSet.
 * @internal
 */
export function LogicalSetIcon({ item, size, ...rest }) {
    return (React.createElement("div", { className: "pw-icon" },
        React.createElement(DocumentIcon, { item: item, size: size, ...rest }),
        React.createElement(SvgLogicalSetOverlay, { className: "pw-icon-overlay", "data-testid": "logical-set-badge" })));
}
