import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * CheckOut SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgCheckOut(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M11.996 3h-3V0zm0 1H8V0H0v16h5.98V6.007h6.016zM10 13.003v1h4v-4h-1v2.293L9.704 8.999l-.708.707 3.297 3.297zm6-6.001V16H7V7.002zm-1 1H8V15h7z" })));
}
