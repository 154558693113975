import React from 'react';
import { Button } from '@itwin/itwinui-react';
/**
 * Primary button for PWModal with high visibility styling
 * @internal
 */
export function PWModalPrimaryButton(props) {
    if (!Object.keys(props).length) {
        return null;
    }
    const { title, ...rest } = props;
    return (React.createElement(Button, { styleType: "high-visibility", ...rest }, title));
}
/**
 * Secondary button for PWModal
 * @internal
 */
export function PWModalSecondaryButton(props) {
    if (!Object.keys(props).length) {
        return null;
    }
    const { title, ...rest } = props;
    return React.createElement(Button, { ...rest }, title);
}
/**
 * Tertiary button for PWModal - places on left side
 * @internal
 */
export function PWModalTertiaryButton(props) {
    if (!Object.keys(props).length) {
        return null;
    }
    const { title, ...rest } = props;
    return (React.createElement(Button, { style: { marginRight: 'auto', ...rest.style }, ...rest }, title));
}
