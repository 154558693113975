import React from 'react';
import { PWThemeProvider } from '@bentley/pw-components';
import { ShareLink } from '../components/shareLink';

export function AppRoutes(): JSX.Element {
  return (
    <PWThemeProvider>
      <ShareLink />
    </PWThemeProvider>
  );
}
