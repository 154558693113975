import { PWTooltip } from '@bentley/pw-components';
import { SvgMicrosoftTeams, SvgProjectWiseHollow, SvgProjectWiseWeb, SvgProjectWiseWebView, SvgVersions } from '@bentley/pw-file-icons';
import { SvgHelpCircularHollow } from '@itwin/itwinui-icons-react';
import { Anchor, RadioTile, RadioTileGroup } from '@itwin/itwinui-react';
import React from 'react';
import { Trans } from 'react-i18next';
import { t } from '../../services/translation';
const teamsHelpUrl = 'https://communities.bentley.com/products/projectwise/content_management/w/wiki/48908/microsoft-teams-integration';
export function AdvancedOptions({ disabledApplications = [], hiddenApplications = [], items, selectedApp, showDocumentsButton, setSelectedApp }) {
    return (React.createElement(RadioTileGroup, { label: items.length == 1 ? t('link.createLinkFor') : t('link.createLinksFor'), className: "link-radio-group" },
        !hiddenApplications.includes('Ask') && (React.createElement(RadioTile, { defaultChecked: selectedApp == 'Ask', icon: React.createElement(SvgHelpCircularHollow, null), label: t('link.askToSelect'), name: "application", value: "Ask", "data-testid": "ask-application", onInput: () => setSelectedApp('Ask'), wrapperProps: { className: 'link-tile' } })),
        !hiddenApplications.includes('Web') && (React.createElement(RadioTile, { defaultChecked: selectedApp == 'Web', icon: showDocumentsButton ? React.createElement(SvgVersions, null) : React.createElement(SvgProjectWiseWeb, null), label: showDocumentsButton ? t('link.documents') : t('link.projectWiseWeb'), name: "application", value: "Web", "data-testid": "web-application", onInput: () => setSelectedApp('Web'), disabled: disabledApplications.includes('Web'), wrapperProps: { className: 'link-tile' } })),
        !hiddenApplications.includes('Explorer') && (React.createElement(PWTooltip, { content: disabledApplications.includes('Explorer')
                ? t('link.explorerNotSupported')
                : undefined },
            React.createElement(RadioTile, { defaultChecked: selectedApp == 'Explorer', icon: React.createElement(SvgProjectWiseHollow, { "data-testid": "explorer-icon" }), label: t('link.projectWiseExplorer'), name: "application", value: "Explorer", "data-testid": "explorer-application", onInput: () => setSelectedApp('Explorer'), disabled: disabledApplications.includes('Explorer'), wrapperProps: { className: 'link-tile' } }))),
        !hiddenApplications.includes('WebView') && (React.createElement(PWTooltip, { content: t('link.webViewLinkHint') },
            React.createElement(RadioTile, { defaultChecked: selectedApp == 'WebView', icon: React.createElement(SvgProjectWiseWebView, null), label: t('link.projectWiseWebView'), name: "application", value: "WebView", "data-testid": "webview-application", onInput: () => setSelectedApp('WebView'), disabled: disabledApplications.includes('WebView'), wrapperProps: { className: 'link-tile' } }))),
        !hiddenApplications.includes('Teams') && (React.createElement(PWTooltip, { content: disabledApplications.includes('Teams') ? (React.createElement(Trans, { i18nKey: "link.teamsLearnMore" },
                "This connection does not have a default Microsoft Teams tab. To learn more about ProjectWise Teams integration, visit",
                ' ',
                React.createElement(Anchor, { className: "tooltip-anchor", href: teamsHelpUrl, target: "_blank", rel: "noopener noreferrer", isExternal: true }, "Bentley Communities"),
                ".")) : undefined, placement: "top-end" },
            React.createElement(RadioTile, { defaultChecked: selectedApp == 'Teams', icon: React.createElement(SvgMicrosoftTeams, { "data-testid": "teams-icon" }), label: t('link.microsoftTeams'), name: "application", value: "Teams", "data-testid": "teams-application", onInput: () => setSelectedApp('Teams'), disabled: disabledApplications.includes('Teams'), wrapperProps: { className: 'link-tile' } })))));
}
