import { itemIsDataItem, itemIsFileType } from '../../properties';
import { flattenProperties } from './parseResponse';
/**
 * Returns parent of item if item was queried with proper relationshipInstance
 * @param item Item returned from a WSG query
 * @returns Parent of item with flattened properties
 */
export function parseRelatedParent(item) {
    var _a, _b;
    const parentInstance = (_b = (_a = item.relationshipInstances) === null || _a === void 0 ? void 0 : _a.find(({ className }) => className == 'DocumentParent' || className == 'ProjectParent')) === null || _b === void 0 ? void 0 : _b.relatedInstance;
    if (!parentInstance) {
        return undefined;
    }
    const parent = flattenProperties(parentInstance);
    return parent;
}
/**
 * Returns workflow commands if item was queried with proper relationshipInstance
 * @param item Item returned from a WSG query
 * @returns Workflow commands
 */
export function parseRelatedCommands(item) {
    var _a, _b;
    if (!itemIsDataItem(item)) {
        return [];
    }
    const commandInstances = (_b = (_a = item.relationshipInstances) === null || _a === void 0 ? void 0 : _a.filter(({ className }) => className == 'DocumentCommand')) === null || _b === void 0 ? void 0 : _b.map(({ relatedInstance }) => relatedInstance);
    return commandInstances !== null && commandInstances !== void 0 ? commandInstances : [];
}
/**
 * Returns environment instances (sheets) if item was queried with proper relationshipInstance
 * @param item Item returned from a WSG query
 * @returns Environment instances (sheets)
 */
export function parseRelatedEnvironmentSheets(item) {
    var _a, _b;
    if (!itemIsDataItem(item)) {
        return [];
    }
    const environmentInstances = (_b = (_a = item.relationshipInstances) === null || _a === void 0 ? void 0 : _a.filter(({ className }) => className == 'DocumentEnvironment')) === null || _b === void 0 ? void 0 : _b.map(({ relatedInstance }) => relatedInstance);
    return environmentInstances !== null && environmentInstances !== void 0 ? environmentInstances : [];
}
/**
 * Returns set relationship instance if item was queried with proper relationshipInstance
 * @param item Item returned from a WSG query
 * @param set Set returned from a WSG query
 * @returns Set relationship instance
 */
export function parseSetRelationshipInstance(item, set) {
    var _a;
    if (!itemIsFileType(item)) {
        return undefined;
    }
    const relationshipInstance = (_a = item.relationshipInstances) === null || _a === void 0 ? void 0 : _a.find(({ className, relatedInstance }) => className == 'SetDocument' && relatedInstance.instanceId == set.instanceId);
    return relationshipInstance;
}
