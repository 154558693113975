// https://github.com/iTwin/iTwinUI/wiki/FAQ#fonts
import '@fontsource/noto-sans-mono/400.css';
import '@fontsource/noto-sans/300.css';
import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/600.css';
import '@fontsource/noto-sans/700.css';
// https://github.com/iTwin/iTwinUI/wiki/Styling#v3
import '@itwin/itwinui-react/styles.css';
import React from 'react';
import { useTernaryDarkMode } from 'usehooks-ts';
import { ThemeProvider } from '@itwin/itwinui-react';
/**
 * Wrapper around ThemeProvider to apply the correct theme based on the user's
 * dark mode preference. Also imports iTwin styles and fonts.
 *
 * **Not for use inside a package;
 * use `<ThemeProvider theme="inherit">` from iTwin inside a package.**
 *
 * To set the user's dark mode preference, use
 * `useTernaryDarkMode` from `usehooks-ts`.
 * @param props PWThemeProvider props
 * @returns JSX.Element
 */
export function PWThemeProvider({ children }) {
    const { ternaryDarkMode } = useTernaryDarkMode();
    const theme = ternaryDarkMode == 'system' ? 'os' : ternaryDarkMode;
    return (React.createElement(ThemeProvider, { theme: theme, style: { height: '100%' }, themeOptions: { applyBackground: true }, "data-testid": "pw-theme-provider" }, children));
}
