import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * ProjectWiseWeb SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgProjectWiseWeb(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "m12.25 8.5a3.75 3.75 0 1 0 3.75 3.75 3.77731 3.77731 0 0 0 -3.75-3.75m-3.25 4h1a5.23676 5.23676 0 0 0 .25 1.5h-0.75a3.1505 3.1505 0 0 1 -0.5-1.5m3.5-2.5v-0.95a1.8605 1.8605 0 0 1 1 0.95m0.2 0.5a4.01257 4.01257 0 0 1 .3 1.5h-1.5v-1.5m-0.5-1.45v0.95h-1a1.70016 1.70016 0 0 1 1-0.95m0 1.45v1.5h-1.5a4.01257 4.01257 0 0 1 .3-1.5m-0.8 1.5h-1a3.1505 3.1505 0 0 1 0.5-1.5h0.75a5.23676 5.23676 0 0 0 -.25 1.5m0.5 0.5h1.5v1.5h-1.2a4.01257 4.01257 0 0 1 -.3-1.5m1.5 2v0.95a1.8605 1.8605 0 0 1 -1-0.95m1.5 0.95v-0.95h1a1.70016 1.70016 0 0 1 -1 0.95m0-1.45v-1.5h1.5a4.01257 4.01257 0 0 1 -.3 1.5m0.8-1.5h1a3.1505 3.1505 0 0 1 -0.5 1.5h-0.75a5.23676 5.23676 0 0 0 .25-1.5m0-0.5a5.23676 5.23676 0 0 0 -.25-1.5h0.75a3.1505 3.1505 0 0 1 0.5 1.5m-0.9-2h-0.55a4.99641 4.99641 0 0 0 -.4-0.7 4.2334 4.2334 0 0 1 0.95 0.7m-3.75-0.7a4.99641 4.99641 0 0 0 -.4 0.7h-0.55a4.2334 4.2334 0 0 1 0.95-0.7m-0.95 5.2h0.55a4.99641 4.99641 0 0 0 .4 0.7 4.2334 4.2334 0 0 1 -0.95-0.7m3.75 0.7a3.18789 3.18789 0 0 0 .4-0.7h0.55a4.2334 4.2334 0 0 1 -0.95 0.7m-3.44566-13.20244.1225-.14771-1.45655-.4355 4.30054-1.41436 0.82917 2.87915-1.48834-.41776-.0408.0456-3.17722 4.08615-.68198-.20914-1.43711-.44181.0014-.00186 1.54672-1.98774-.016-.00507zm-9.08835 3.63663-.83335-2.878 4.30035-1.41431-0.97405 1.23535.15515.04181 4.59586 1.4425-1.35346 1.7615-.04175.05365-.07935-.02442-4.6895-1.44144-.07121-.01535zm2.67996 4.36762-.12458.14771 1.4581.43634-4.30045 1.41416-0.82904-2.87915 1.43106.40204.066-.0125 3.20886-4.1285 2.1205.65006-.01892.02456-1.52936 1.96534.01606.00495zm5.59271-.88776-3.74783-1.17633 1.33582-1.73886.0589-.07572 1.52011.46616 3.24893 0.99906.06916.01779 1.01135-1.24103 0.52057 1.79816a4.21564 4.21564 0 0 0 -4.017 0.95078z" })));
}
