import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * StatusInUseHollow SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgStatusInUseHollow(props) {
    return (React.createElement(Svg, { style: { background: '#fff' }, ...props },
        React.createElement("path", { fill: "#FF0000", xmlns: "http://www.w3.org/2000/svg", d: "M2,14V2H14V14H2M1,15H15V1H1V15Z" }),
        React.createElement("polygon", { fill: "#FF0000", xmlns: "http://www.w3.org/2000/svg", points: "4 4.707 10.293 11 6 11 6 12 12 12 12 6 11 6 11 10.293 4.707 4 4 4.707" })));
}
