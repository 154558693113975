import React, { useMemo } from 'react';
import { SvgMore } from '@itwin/itwinui-icons-react';
import { ButtonGroup, DropdownMenu, IconButton } from '@itwin/itwinui-react';
import { formatMenuItems } from './formatMenuItems';
import { menuItemActionToMenuItem } from './menuItemActionToMenuItem';
import { ToolbarButton } from './toolbarButton';
/**
 * Wrapper around ButtonGroup to display a toolbar with overflow button.
 * @param props PWToolbar props
 * @returns JSX.Element
 */
export function PWToolbar({ buttons, numVisibleButtons = buttons.length, ...buttonGroupProps }) {
    const buttonWidth = 50;
    const maxWidth = numVisibleButtons
        ? `${numVisibleButtons * buttonWidth + 5}px`
        : 'unset';
    buttons = formatMenuItems(buttons);
    const toolbarButtonCount = useMemo(() => {
        if (numVisibleButtons >= buttons.length) {
            return buttons.length + 1;
        }
        return numVisibleButtons - 1;
    }, [buttons.length, numVisibleButtons]);
    if (!buttons.length) {
        return null;
    }
    if (buttons.length <= toolbarButtonCount) {
        return (React.createElement("div", { style: { maxWidth } },
            React.createElement(ButtonGroup, { role: "toolbar", ...buttonGroupProps }, buttons.map((button) => (React.createElement(ToolbarButton, { action: button, key: button.title }))))));
    }
    // Todo: overflowButton is the native iTwin implementation to add a 'more' button based on space
    // We're having trouble with it closing when updating the buttons https://github.com/iTwin/iTwinUI/issues/1118
    // Once the bug is fixed, we can go back to the native implementation
    return (React.createElement("div", { style: { maxWidth } },
        React.createElement(ButtonGroup, { ...buttonGroupProps },
            buttons.slice(0, toolbarButtonCount).map((button) => (React.createElement(ToolbarButton, { action: button, key: button.title }))),
            React.createElement(DropdownMenu, { className: "context-menu-list", menuItems: (close) => buttons
                    .slice(toolbarButtonCount)
                    .map((button) => menuItemActionToMenuItem(button, close)) },
                React.createElement(IconButton, { "data-testid": "MoreButton" },
                    React.createElement(SvgMore, null))))));
}
