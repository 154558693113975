import React from 'react';
import { Overlay, ProgressRadial } from '@itwin/itwinui-react';
import { LoadingPage } from '../loading';
/**
 * Displays loading page in modal if loading; otherwise displays modal content
 * @internal
 */
export function PWModalLoadingWrapper({ isLoading, loadingStyle, loadingText, children }) {
    if (!isLoading) {
        return React.createElement(React.Fragment, null, children);
    }
    if (loadingStyle == 'spinnerOverlay') {
        return (React.createElement(Overlay, { content: React.createElement(ProgressRadial, { size: "large", role: "progressbar" }) }, children));
    }
    return (React.createElement(LoadingPage, { loadingSubText: loadingText, className: "pw-modal-loading-page" }));
}
