/**
 * Format a date time string to a consistent format.
 * @internal
 */
export function formatDateTime(dateTimeString) {
    if (!dateTimeString) {
        return '';
    }
    const date = new Date(dateTimeString).toLocaleString(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
    });
    if (date == 'Invalid Date') {
        return dateTimeString;
    }
    return date;
}
const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
/**
 * Format a file size to a consistent format.
 * @internal
 */
export function formatFileSize(fileSize) {
    if (!fileSize) {
        return '';
    }
    const bytes = +fileSize || 0;
    if (bytes == 0) {
        return '';
    }
    let unitIdx = Math.floor(Math.log(bytes) / Math.log(1000));
    if (units.length <= unitIdx) {
        // use PB as maximum unit
        unitIdx = units.length - 1;
    }
    if (unitIdx == 0) {
        // use KB as minimum unit
        unitIdx = 1;
    }
    const size = bytes / Math.pow(1024, unitIdx);
    let precision = 2 - Math.floor(Math.log(size < 1 ? 1 : size) / Math.LN10); // Keep 3 significant digits
    precision = precision >= 0 && isFinite(precision) ? precision : 0;
    const magnitude = size < 0.05 && unitIdx <= 1 ? '0' : size.toFixed(precision);
    const unit = units[unitIdx];
    return `${magnitude} ${unit}`;
}
