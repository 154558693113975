import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * StatusInUse SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgStatusInUse(props) {
    return (React.createElement(Svg, { style: { background: '#fff' }, ...props },
        React.createElement("path", { fill: "#cb2026", d: "M1,1v14h14V1H1z M12,12H6v-1h4.293L4,4.707L4.707,4L11,10.293V6h1V12z" })));
}
