import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * SavedSearches SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgSavedSearches(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M16 14.586L14.586 16l-5.113-5.113A5.998 5.998 0 116 0a5.96 5.96 0 013.98 1.524L11.028.497l1.358 1.316-6.831 6.684-2.941-2.861L3.972 4.32 5.56 5.86l2.972-2.915A3.92 3.92 0 006 2a4 4 0 104 4 3.889 3.889 0 00-.042-.412l1.675-1.638a5.95 5.95 0 01-.746 5.523z" })));
}
