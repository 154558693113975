import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * LogicalSetOverlay SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgLogicalSetOverlay(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("rect", { y: "0.5", x: "3", height: "0.5", width: "2.25", fill: "#fff" }),
        React.createElement("rect", { y: "1", x: "4", height: "3", width: "0.5", fill: "#fff" }),
        React.createElement("rect", { y: "2", x: "4.5", height: "0.5", width: "2.25", fill: "#fff" }),
        React.createElement("rect", { y: "3.5", x: "4.5", height: "0.5", width: "2.25", fill: "#fff" })));
}
