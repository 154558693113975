import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * Vue SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgFiletypeVue(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M11,3V0H2V16H14V3Z", fill: "#3fb984" }),
        React.createElement("path", { d: "M11,0l3,3H11Z", fill: "#276b4a" }),
        React.createElement("path", { d: "M11.88831,4.86636H9.133L8.02832,6.7919,6.92023,4.86636H3.20746l4.82086,8.31915,4.76422-8.31915Zm-6.46576.5H6.631l.96387,1.67493L8.029,7.7955l.433-.75476.96063-1.67438h1.17968l-2.574,4.5Z", fill: "#fff" })));
}
