import { itemIsDataItem } from '@bentley/pw-api';
import { t } from '../translation';
import { isSavedSearch } from './savedSearchConverter';
export function plainTextLinks(itemLinks) {
    const plainTextLinks = itemLinks
        .map((itemLink) => itemLink.link)
        .join('\r\n');
    return plainTextLinks;
}
export function htmlTextLinks(itemLinks) {
    const linkWrapper = document.createElement('div');
    for (const { item, link } of itemLinks) {
        const anchor = document.createElement('a');
        anchor.href = link;
        anchor.text = itemName(item);
        linkWrapper.append(anchor);
        const br = document.createElement('br');
        linkWrapper.append(br);
    }
    return linkWrapper.innerHTML;
}
function itemName(item) {
    if (isSavedSearch(item)) {
        return item.Name;
    }
    if (itemIsDataItem(item) && item.Version) {
        return t('link.nameWithVersion', {
            name: item.Name,
            version: item.Version
        });
    }
    return item.Name;
}
