import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * StatusFinal SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgStatusFinal(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("polygon", { fill: "#3287c8", points: "6.55 7.59 8.48 10.22 10.53 9.18 8.46 6.59 6.55 7.59" }),
        React.createElement("path", { fill: "#3287c8", d: "M4.73,6.11a.93.93,0,0,0,.7.9L5.2,6.14l1.19-.59a.93.93,0,0,0-1.67.56Z" }),
        React.createElement("path", { fill: "#3287c8", d: "M8,1a7,7,0,1,0,7,7A7,7,0,0,0,8,1ZM8,11.26,5.64,7.87l-.1-.4A1.37,1.37,0,1,1,6.79,5.35L7,5.23,9,6.16l2.76,3.21Z" }),
        React.createElement("path", { fill: "#fff", d: "M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,15a7,7,0,1,1,7-7A7,7,0,0,1,8,15Z" }),
        React.createElement("path", { fill: "#fff", d: "M7,5.23l-.25.12A1.37,1.37,0,1,0,5.54,7.48l.1.4L8,11.26l3.75-1.88L9,6.16ZM5.2,6.14,5.42,7a.93.93,0,1,1,1-1.46Zm3.28,4.09L6.55,7.59l1.91-1,2.07,2.58Z" })));
}
