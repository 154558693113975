import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * Staad SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgFiletypeStaad(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M11,3V0H2V16H14V3Z", fill: "#e72e2e" }),
        React.createElement("path", { d: "M11,0l3,3H11Z", fill: "#a22022" }),
        React.createElement("path", { d: "M4.6538,12.61229a12.1812,12.1812,0,0,1,1.7358-.60995c.06545-.01769.13153-.03424.19771-.05093a13.00041,13.00041,0,0,1,1.76312-.3189c.057-.00627.11355-.011.17036-.01649q.13866-.01347.27617-.02389a11.534,11.534,0,0,1,2.74835.11085,9.04491,9.04491,0,0,1,1.55481.39694,26.48979,26.48979,0,0,0-4.19255.13817,26.63936,26.63936,0,0,0-6.00769,1.35034A10.39715,10.39715,0,0,1,4.6538,12.61229Z", fill: "#fff" }),
        React.createElement("path", { d: "M11.428,8.04671l-1.39738-1.1522.3661,4.445a10.7538,10.7538,0,0,1,1.14856.12567c.06223.01036.12255.02314.184.03454Zm-.74969-.47589.24034.253.44505,1.55848-.264-.09371L10.8152,8.159l-.1652.9584-.24687-.08267Zm-.29382,1.7426-.0103-.1751,1.06492.37536.01236.16571Zm.89745,2.00377-.34464-1.21851L10.81492,11.243l-.24579-.039.21524-1.65273.24951.0909.49565,1.71436Z", fill: "#fff" }),
        React.createElement("path", { d: "M8.35043,11.39471c.057-.00627.11355-.011.17036-.0165q.13866-.01346.27616-.02388c.38267-.02868.75781-.03891,1.12433-.03291L9.47743,5.61979,8.25176,4.44307v6.96427C8.28478,11.40345,8.31731,11.39835,8.35043,11.39471Z", fill: "#fff" }),
        React.createElement("path", { d: "M6.3896,11.76454c.06546-.01769.13153-.03424.19772-.05093.37752-.0954.76556-.17384,1.16093-.23641V4.44307L6.52258,5.61979l-.48632,6.24715C6.15325,11.83169,6.27043,11.79669,6.3896,11.76454Z", fill: "#fff" }),
        React.createElement("path", { d: "M4.572,8.04671,4.1748,12.59909q.23451-.11664.479-.22459c.28775-.12676.58622-.24316.893-.34965l.42252-5.13034ZM4.94238,7.968,5.028,9.34417l.42086-1.76469.319-.25021L5.1427,9.841l-.22448.09665L4.68749,8.14286Zm.17641,4.07929-.099-1.16181-.35659,1.3303-.23976.08867L4.90506,10.418l.2295-.08488L5.36028,11.958Zm.42738-2.013-1.068.41327.01659-.18934,1.07291-.44308Z", fill: "#fff" })));
}
