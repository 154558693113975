import { getEncodedContext, getItemType } from './getShareableLink';
export function getTeamsLink(connection, item, teamsTabLink, encodeSubEntityId = false) {
    teamsTabLink = teamsTabLink || (connection === null || connection === void 0 ? void 0 : connection.DefaultTab);
    const itemId = item.instanceId;
    const itemType = getItemType(item.className);
    const projectId = item.ParentGuid;
    const encodedContext = encodeSubEntityId
        ? getEncodedContext(connection.DatasourceInstanceId, itemId, itemType, connection.ProjectId)
        : undefined;
    return getTeamsLinkCore({
        teamsTabLink,
        itemId,
        itemType,
        projectId,
        encodedContext
    });
}
export function getTeamsSavedSearchLink(connection, savedSearchId, teamsTabLink, encodeSubEntityId = false) {
    teamsTabLink = teamsTabLink || (connection === null || connection === void 0 ? void 0 : connection.DefaultTab);
    const encodedContext = encodeSubEntityId
        ? getEncodedContext(connection.DatasourceInstanceId, savedSearchId, 'saved-search', connection.ProjectId)
        : undefined;
    return getTeamsLinkCore({
        savedSearchId,
        teamsTabLink,
        encodedContext
    });
}
export function getTeamsLinkCore({ teamsTabLink, itemId, itemType, projectId, savedSearchId, encodedContext }) {
    if (!teamsTabLink) {
        return '';
    }
    const teamsUrl = new URL(teamsTabLink);
    const context = JSON.parse(teamsUrl.searchParams.get('context'));
    context.subEntityId =
        encodedContext !== null && encodedContext !== void 0 ? encodedContext : ['', projectId, itemId, itemType, savedSearchId].join('--');
    teamsUrl.searchParams.set('context', JSON.stringify(context));
    return teamsUrl.href;
}
