/**
 * Attempts to determine the current environment based on the URL.
 * @returns 'localhost', 'dev', 'qa', 'release', or 'prod'
 */
export function productionEnvironment() {
    try {
        if (!document ||
            !window ||
            !(window === null || window === void 0 ? void 0 : window.location) ||
            window.location.origin.includes('local')) {
            return 'localhost';
        }
        if (location.origin.includes('dev-')) {
            return 'dev';
        }
        if (location.origin.includes('release')) {
            return 'release';
        }
        if (location.origin.includes('qa-')) {
            return 'qa';
        }
        return 'prod';
    }
    catch {
        return 'prod';
    }
}
