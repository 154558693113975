import React from 'react';
import { DropdownMenu, IconButton } from '@itwin/itwinui-react';
import { menuItemActionToMenuItem } from './menuItemActionToMenuItem';
/**
 * Dropdown menu in an IconButton with child items.
 * @internal
 */
export function ToolbarButtonWithChildren({ childItems, icon, iconButtonProps }) {
    const { itemTooltip, title, className } = iconButtonProps;
    return (React.createElement(DropdownMenu, { menuItems: (close) => childItems.map((action) => menuItemActionToMenuItem(action, close)) },
        React.createElement(IconButton, { label: itemTooltip || title, className: `${className !== null && className !== void 0 ? className : ''} button-with-children`, "data-testid": iconButtonProps['data-testid'] }, icon)));
}
