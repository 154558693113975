import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * MicrosoftTeams SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgMicrosoftTeams(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "m10.23828 5.53987c-.13035-.05577-.17066-.12766-.35073-.21568v-2.21052c.17939-.08734.22038-.15991.35073-.21567a1.37267 1.37267 0 0 1 .553-.11221 1.39179 1.39179 0 0 1 .557.11221 1.43365 1.43365 0 0 1 .00134 2.64187 1.391 1.391 0 0 1 -.55632.11221 1.37107 1.37107 0 0 1 -.55502-.11221zm2.34557 1.022h-2.6963v6.35407c.18006.039.24457.06921.37223.08667a3.05769 3.05769 0 0 0 .42194.03091 3.18688 3.18688 0 0 0 .64838-.06988 1.91528 1.91528 0 0 0 .61478-.23986 1.41158 1.41158 0 0 0 .45823-.45487 1.30794 1.30794 0 0 0 .18074-.71489zm-12.58385-4.85379 9.348-1.61792v15.81968l-9.348-1.61792zm2.5169 4.4936 1.61792-.06517v4.61388l1.07838.06315v-4.71936l1.61792-.07189v-1.07839l-4.31422.26271zm13.48217 4.08645v-2.82732h-2.8757v4.31419h.71893a4.09072 4.09072 0 0 0 .73572-.06988 2.29318 2.29318 0 0 0 .69675-.24322 1.55378 1.55378 0 0 0 .51937-.45823 1.1879 1.1879 0 0 0 .20493-.71554zm-2.45644-4.15632a1.43072 1.43072 0 1 0 -.30772-.45622 1.46145 1.46145 0 0 0 .30772.45622z" })));
}
