import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * CheckIn SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgCheckIn(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M11.996 6.007H5.98V16H0V0h8v4h3.997zM8.996 0v3h3zm1 10.706l3.297 3.297.707-.707-3.296-3.297h2.292V9h-4v4h1zM16 7.002V16H7V7.002zm-1 1H8V15h7z" })));
}
