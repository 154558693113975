import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * StatusLocked SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgStatusLocked(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { fill: "#3287c8", d: "M8,1a7,7,0,1,0,7,7A7,7,0,0,0,8,1Zm2.5,9.72a.52.52,0,0,1-.52.52H6a.52.52,0,0,1-.52-.52V7.9A.52.52,0,0,1,6,7.38h0V6.7A2,2,0,1,1,10,6.7v.68h0a.52.52,0,0,1,.52.52Z" }),
        React.createElement("path", { fill: "#3287c8", d: "M8,5.6a1.1,1.1,0,0,0-1.1,1.1v.68h2.2V6.7A1.1,1.1,0,0,0,8,5.6Z" }),
        React.createElement("path", { fill: "#fff", d: "M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,15a7,7,0,1,1,7-7A7,7,0,0,1,8,15Z" }),
        React.createElement("path", { fill: "#fff", d: "M10,7.38h0V6.7a2,2,0,1,0-3.91,0v.68H6a.52.52,0,0,0-.52.52v2.85a.52.52,0,0,0,.52.52h4a.52.52,0,0,0,.52-.52V7.89A.52.52,0,0,0,10,7.38Zm-.87,0H6.92V6.7a1.1,1.1,0,1,1,2.2,0Z" })));
}
