/**
 * Converts a keyboard action to a click action
 * @param action Action to call on click, will receive event from keyboard
 * @param [monitoredKeys] Optional keys to accept as click, defaults to Enter only
 * @returns Keyboard listener
 */
export function keyPressAsClick(action, monitoredKeys = { Enter: true }) {
    function onKeyPress(event) {
        const capturedKey = event.key == ' ' ? 'Space' : event.key;
        if (monitoredKeys[capturedKey]) {
            action(event);
        }
    }
    return onKeyPress;
}
