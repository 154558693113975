import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * StatusCheckedOut SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgStatusCheckedOut(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { fill: "#cb2026", d: "M8,1a7,7,0,1,0,7,7A7,7,0,0,0,8,1ZM6.93,11.26l-3.2-3.2L4.9,6.88l2,2L11,4.74l1.23,1.17Z" }),
        React.createElement("path", { fill: "#fff", d: "M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,15a7,7,0,1,1,7-7A7,7,0,0,1,8,15Z" }),
        React.createElement("polygon", { fill: "#fff", points: "6.93 8.91 4.9 6.88 3.73 8.05 6.93 11.26 12.27 5.92 11.04 4.74 6.93 8.91" })));
}
