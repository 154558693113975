import { getBuddiRegionEnvironment } from '../buddi/regions';
/**
 * Hardcoded PW Web LD keys so npm package can find the right flags without config
 * @param regionCode BUDDI region code
 * @param dataAccessLevel Optional data access level
 * @returns LD key
 */
export async function lookupLDKey(regionCode, dataAccessLevel) {
    const environment = await getBuddiRegionEnvironment(regionCode);
    if (dataAccessLevel == 'Datasource') {
        return getWebViewLDKey(environment);
    }
    return getPWWebLDKey(environment);
}
function getPWWebLDKey(regionCode) {
    switch (regionCode) {
        case 'Development':
            return '5e45a4f0b5d1b007df8f4829';
        case 'QA':
            return '5e45a4f19ca94d07b8d5c9b7';
        default:
            return '5e45a4f0c38c4907f342b868';
    }
}
function getWebViewLDKey(regionCode) {
    switch (regionCode) {
        case 'Development':
            return '5d5ecc80978a2f085f0ac80e';
        case 'QA':
            return '5d5ecc812916f808bb92cbac';
        default:
            return '5d5ecc80458032086328373c';
    }
}
