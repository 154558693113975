import { useCallback, useMemo } from 'react';
import { AuthorizationService, HttpService } from '@bentley/pw-api';
import { productionEnvironment, useBuddi } from '../buddi';
import { actions } from './actions';
/**
 * Logs user's ProjectWise Web feature usage.
 * NOTE: Will not log usage in localhost.
 * @param logUsageConfig Configuration for logging usage
 * @param logUsageConfig.buddiRegionCode Region code for BUDDI
 * @param logUsageConfig.contextId Context ID for logging usage
 * @param logUsageConfig.getOidcToken Async function to get OIDC token
 * @returns Function configured to log feature usage
 */
export function useLogUsage({ buddiRegionCode, contextId, getOidcToken }) {
    const apiUrl = contextId ? 'ProjectWiseWAC.URL' : 'ProjectWiseWebView.URL';
    const pwWebUrl = useBuddi(apiUrl, buddiRegionCode);
    const httpService = useMemo(() => {
        if (!pwWebUrl) {
            return undefined;
        }
        return new HttpService({
            authorization: new AuthorizationService({ getOidcToken }),
            baseUrl: pwWebUrl
        });
    }, [getOidcToken, pwWebUrl]);
    const sendUsageRequest = useCallback(async (userAction, userData = []) => {
        if (!httpService) {
            return;
        }
        try {
            const action = actions[userAction];
            if (!action) {
                throw new Error(`Invalid usage logging action: ${userAction}`);
            }
            userData.push({ key: 'Location', value: location.hostname });
            const body = JSON.stringify({
                ...action,
                UserData: userData,
                ProjectId: contextId
            });
            const response = await httpService.post('api/trackfeature', body);
            if (!response.ok) {
                throw response;
            }
        }
        catch (e) {
            console.error('Failed to log usage', e);
        }
    }, [httpService, contextId]);
    const logUsage = useCallback((userAction, userData) => {
        if (productionEnvironment() == 'localhost') {
            return;
        }
        void sendUsageRequest(userAction, userData);
    }, [sendUsageRequest]);
    return logUsage;
}
