/**
 * Recursively formats menu items to set hidden and disabled properties.
 * @internal
 */
export function formatMenuItems(menuItems, toolbarDisabled = false) {
    const formattedMenuItems = menuItems
        .map((menuItem) => {
        var _a;
        return ({
            ...menuItem,
            disabled: menuItem.disabled ||
                allChildrenHiddenOrDisabled(menuItem) ||
                toolbarDisabled,
            hidden: menuItem.hidden || allChildrenHidden(menuItem),
            'data-testid': (_a = menuItem['data-testid']) !== null && _a !== void 0 ? _a : `${menuItem.title.replace(/ /g, '')}Button`
        });
    })
        .filter((menuItem) => !menuItem.hidden);
    formattedMenuItems.forEach((menuItem) => {
        if (menuItem.submenuItems) {
            menuItem.submenuItems = formatMenuItems(menuItem.submenuItems, toolbarDisabled);
        }
    });
    return formattedMenuItems;
}
function allChildrenHidden(menuItem) {
    if (!menuItem.submenuItems) {
        return false;
    }
    return menuItem.submenuItems.every((child) => child.hidden);
}
function allChildrenHiddenOrDisabled(menuItem) {
    if (!menuItem.submenuItems) {
        return false;
    }
    return menuItem.submenuItems.every((child) => child.disabled || child.hidden);
}
