import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * StatusReadOnly SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgStatusReadOnly(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("polygon", { fill: "#3388c8", points: "5.27 9.65 5.27 10.1 5.84 10.1 5.84 10.66 6.29 10.66 6.69 10.26 5.68 9.24 5.27 9.65" }),
        React.createElement("path", { fill: "#3388c8", d: "M8,1a7,7,0,1,0,7,7A7,7,0,0,0,8,1ZM9.33,4.9a.59.59,0,0,1,.81,0l1,1a1,1,0,0,1,.08.37.52.52,0,0,1-.16.41l-.73.73L8.6,5.59Zm-1,1L10.1,7.66l-.76.75L8.11,7.18l.41-.41a.14.14,0,0,0,0-.08c0-.08,0-.08-.08-.08s0,0-.08,0L8,7.06l-.43-.43ZM6.53,11.23H4.74V9.45L6.64,7.56,7.06,8l-1,1A.14.14,0,0,0,6,9c0,.08,0,.08.08.08s0,0,.08,0l1-1L8.42,9.34Zm4.38,0L4.74,5.1l.36-.36,6.16,6.16Z" }),
        React.createElement("path", { fill: "#fff", d: "M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,15a7,7,0,1,1,7-7A7,7,0,0,1,8,15Z" }),
        React.createElement("path", { fill: "#fff", d: "M11.08,6.69a.52.52,0,0,0,.16-.41,1,1,0,0,0-.08-.37l-1-1a.59.59,0,0,0-.81,0l-.73.69,1.75,1.83Z" }),
        React.createElement("path", { fill: "#fff", d: "M6.21,9.08s0,0-.08,0S6,9.12,6,9a.14.14,0,0,1,0-.08l1-1-.43-.43L4.74,9.45v1.79H6.53L8.42,9.34,7.18,8.1Zm.08,1.58H5.84V10.1H5.27V9.65l.41-.41,1,1Z" }),
        React.createElement("path", { fill: "#fff", d: "M8.4,6.65s0,0,.08,0,.08,0,.08.08a.14.14,0,0,1,0,.08l-.41.41L9.35,8.42l.76-.75L8.32,5.88l-.76.75L8,7.06Z" }),
        React.createElement("rect", { fill: "#fff", x: "7.75", y: "3.64", width: "0.5", height: "8.71", transform: "translate(-3.31 8.01) rotate(-45.02)" })));
}
