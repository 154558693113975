import React from 'react';
import { SvgFolder, SvgPwProject, SvgPwWorkarea } from '@itwin/itwinui-icons-color-react';
import { SvgDatabase } from '@itwin/itwinui-icons-react';
import { pwIconProps } from '../sizes/iconClassName';
/**
 * Icon for PWProject.
 * @internal
 */
export function ProjectIcon({ item, size, ...rest }) {
    const iconProps = pwIconProps(size, rest);
    if (item.IsRichProject && item.TypeString == 'Project' && item.instanceId) {
        return React.createElement(SvgPwWorkarea, { "data-testid": "work-area-filetype", ...iconProps });
    }
    if (item.IsRichProject && item.TypeString == 'Project' && !item.instanceId) {
        return React.createElement(SvgDatabase, { "data-testid": "datasource-filetype", ...iconProps });
    }
    if (item.IsRichProject && item.TypeString == 'ConnectedProject') {
        return (React.createElement(SvgPwProject, { "data-testid": "connected-project-filetype", ...iconProps }));
    }
    return React.createElement(SvgFolder, { "data-testid": "folder-filetype", ...iconProps });
}
