import './toolbarButton.css';
import React from 'react';
import { IconButton } from '@itwin/itwinui-react';
import { ToolbarButtonWithChildren } from './toolbarButtonWithChildren';
/**
 * IconButton or DropdownMenu with child items.
 * @internal
 */
export function ToolbarButton({ action: { groupLabel, icon, itemTooltip, startGroup, submenuItems, ...iconButtonProps } }) {
    if (submenuItems === null || submenuItems === void 0 ? void 0 : submenuItems.length) {
        return (React.createElement(ToolbarButtonWithChildren, { childItems: submenuItems, icon: icon, iconButtonProps: iconButtonProps }));
    }
    const { title, ...rest } = iconButtonProps;
    return (React.createElement(IconButton, { ...rest, label: itemTooltip || title }, icon));
}
