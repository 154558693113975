import React from 'react';
import { pwIconProps } from '../sizes/iconClassName';
import { SvgVersions } from '../svg';
/**
 * Icon for multiple PWItems.
 * @internal
 */
export function MultipleItemsIcon({ size, ...rest }) {
    return (React.createElement(SvgVersions, { "data-testid": "multiple-items-filetype", ...pwIconProps(size, rest) }));
}
