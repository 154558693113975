import { getBuddiRegionEnvironment } from '../buddi/regions';
/**
 * Hardcoded AppInsights keys so npm package can add telemetry without config
 * @param regionCode BUDDI region code
 * @param dataAccessLevel Optional data access level
 * @returns AppInsights key
 */
export async function lookupInstrumentationKey(regionCode, dataAccessLevel) {
    const environment = await getBuddiRegionEnvironment(regionCode);
    if (dataAccessLevel == 'Datasource') {
        return getWebViewKey(environment);
    }
    return getPWWebKey(environment);
}
function getPWWebKey(regionCode) {
    switch (regionCode) {
        case 'Development':
            return 'b5c72442-4104-4dde-89be-c855263af5fb';
        case 'QA':
            return 'a5a6c518-b5ff-4fd2-80f6-9cfdf00f2feb';
        default:
            return 'db2e8017-47c4-4dcb-a27e-f56c099ecda3';
    }
}
function getWebViewKey(regionCode) {
    switch (regionCode) {
        case 'Development':
            return 'ad89d850-8428-486b-85f3-d41eaa0211c3';
        case 'QA':
            return '65680a0b-705a-4e50-9064-380ec0c58f5c';
        default:
            return 'c5676b7f-a425-444a-bfae-1a176c58dc4b';
    }
}
