import { parseResponseRelationshipInstances, query } from '../http';
/**
 * Returns the states of a workflow
 * @param getWorkflowStatesOptions Options for retrieving states
 * @param getWorkflowStatesOptions.httpService Configured http service for the datasource
 * @param getWorkflowStatesOptions.requestOptions Options for the http request
 * @param getWorkflowStatesOptions.workflowId Workflow instanceId
 * @returns An array of states; will throw if not found
 */
export async function getWorkflowStates({ httpService, requestOptions, workflowId }) {
    const select = [
        workflowSelect,
        workflowStatesSelect,
        workflowStatesStateSelect
    ];
    const url = query({
        schemaName: 'PW_WSG',
        className: 'Workflow',
        instanceId: String(workflowId),
        select: select
    });
    const response = await httpService.get(url, requestOptions);
    const states = await parseResponseRelationshipInstances(response);
    states.forEach(appendOrderIndexOnState);
    states.sort((a, b) => a.OrderIndex - b.OrderIndex);
    return states;
}
const workflowSelect = {
    properties: 'all'
};
const workflowStatesSelect = {
    relationshipClass: {
        relationshipClassName: 'WorkflowStates'
    },
    properties: 'all'
};
const workflowStatesStateSelect = {
    relationshipClass: {
        relationshipClassName: 'WorkflowStates',
        direction: 'forward',
        relatedClassName: 'State'
    },
    properties: 'all'
};
function appendOrderIndexOnState(state) {
    var _a, _b;
    const workflowStateRelationship = (_a = state.relationshipInstances) === null || _a === void 0 ? void 0 : _a[0];
    state.OrderIndex = (_b = workflowStateRelationship === null || workflowStateRelationship === void 0 ? void 0 : workflowStateRelationship.properties) === null || _b === void 0 ? void 0 : _b.OrderIndex;
}
