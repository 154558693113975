import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * ProjectWiseHollow SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgProjectWiseHollow(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M9.9505,5.7765l.0183.0055L8.2011,7.9354l-.0016.002,1.6424.4786.7794.2266,3.6311-4.4267.0466-.0494L16,4.6191,15.0524,1.5,10.1375,3.0322l1.6646.4718-.14.16Zm-3.9,4.4463-.0183-.0054L7.78,8.0883l.0216-.0266L5.3782,7.3575,1.7109,11.83l-.0754-.0135L0,11.3809.9475,14.5l4.9148-1.532-1.6664-.4727.1423-.16ZM13.57,9.74l-.079-.0193L9.7779,8.6384l-1.7373-.505-.0673.082L6.4467,10.0992l5.2522,1.5627.1781.0449-1.1139,1.3386,4.9149-1.5322-.9522-3.1177ZM2.4282,6.2618l.0814.0166L7.869,7.84l.0907.0264.0477-.0581L9.5542,5.9,4.3018,4.3373,4.1245,4.292,5.2377,2.9537.323,4.4859l.9524,3.1178Z" })));
}
