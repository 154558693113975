import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * UserManagement SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgUserManagement(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "m3.55975 3.45581c0-2.63306 1.30188-3.45581 2.92925-3.45581s2.92921.82275 2.92921 3.45581a3.229 3.229 0 0 1 -2.92921 3.45575 3.229 3.229 0 0 1 -2.92925-3.45575zm3.44025 8.54419a4.9782 4.9782 0 0 1 2.54944-4.33331l-.70081-.36219h-.16278a3.70284 3.70284 0 0 1 -2.18414.81324 3.77683 3.77683 0 0 1 -2.2096-.81324h-.16278l-2.27826 1.18659a.62711.62711 0 0 0 -.3255.31647l-1.46457 3.24316a.84435.84435 0 0 0 0 .63287.74709.74709 0 0 0 .65085.31641h6.38892a5.02108 5.02108 0 0 1 -.10077-1zm9 .71v-1.43l-1.1-.22a4.31766 4.31766 0 0 0 -.19-.45l.62-.93-1.01-1.01-.93.62a2.6192 2.6192 0 0 0 -.46-.19l-.22-1.1h-1.43l-.22 1.1a2.61978 2.61978 0 0 0 -.46.19l-.93-.62-1.01 1.01.62.93a3.70445 3.70445 0 0 0 -.19.46l-1.09.22v1.43l1.1.22a2.5251 2.5251 0 0 0 .19.45l-.62.94 1.01 1.01.94-.63a2.43082 2.43082 0 0 0 .44.19l.23 1.1h1.42l.23-1.11a3.38079 3.38079 0 0 0 .44-.18l.94.62 1.01-1.01-.62-.94a3.01143 3.01143 0 0 0 .18-.45zm-4 .81a1.525 1.525 0 1 1 1.52-1.52 1.5215 1.5215 0 0 1 -1.52 1.52z" })));
}
