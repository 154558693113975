import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * Workflows SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgWorkflows(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M8,4.5v4a.5.5,0,0,1-.5.5h-6A.5.5,0,0,1,1,8.5v-4A.5.5,0,0,1,1.5,4H4L3,2H4V0H5V2H6L5,4H7.5A.5.5,0,0,1,8,4.5ZM15.5,11H13l1-2H13V6.5a.5.5,0,0,0-.5-.5H9V7h3V9H11l1,2H9.5a.5.5,0,0,0-.5.5v4a.5.5,0,0,0,.5.5h6a.5.5,0,0,0,.5-.5v-4A.5.5,0,0,0,15.5,11Z" })));
}
