import './getShareableLinkUI.css';
import React from 'react';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Flex, LabeledTextarea, ModalButtonBar, ModalContent, ThemeProvider } from '@itwin/itwinui-react';
import { AdvancedOptions } from '../../components/advancedOptions';
import { CopyButton } from '../../components/copyButton';
import { useSelectedApplication } from '../../hooks/useSelectedApplication';
import { asItem } from '../../services/link';
import { t } from '../../services/translation';
export function GetShareableLinkUI({ CloseButton, consumerApp, dataAccessLevel, disabledApplications, hiddenApplications, items, useFileName = false, getLink }) {
    const [selectedApp, setSelectedApp] = useSelectedApplication({
        consumerApp,
        dataAccessLevel,
        disabledApplications,
        hiddenApplications
    });
    function showAdvancedOptions() {
        if (dataAccessLevel == 'Datasource') {
            return false;
        }
        return true;
    }
    function showDocumentsButton() {
        return (consumerApp == 'ProjectWise 365' ||
            consumerApp == 'ProjectWise 365 in Teams' ||
            consumerApp == 'Synchro');
    }
    return (React.createElement(ThemeProvider, { theme: "inherit" },
        React.createElement(ModalContent, { className: "share-link-ui" },
            React.createElement(Flex, { flexDirection: "column", alignItems: "stretch" },
                React.createElement(ItemSummary, { item: items.map(asItem), useFileName: useFileName }),
                React.createElement(LabeledTextarea, { label: items.length == 1 ? t('link.copyLink') : t('link.copyLinks'), readOnly: true, value: items.map((item) => getLink(item, selectedApp)).join('\r\n'), rows: Math.min(items.length, 3), wrapperProps: { className: 's-l-input-link' }, wrap: "off", "data-testid": "shareableLink" }),
                showAdvancedOptions() && (React.createElement(AdvancedOptions, { disabledApplications: disabledApplications, hiddenApplications: hiddenApplications, items: items, selectedApp: selectedApp, showDocumentsButton: showDocumentsButton(), setSelectedApp: setSelectedApp })))),
        React.createElement(ModalButtonBar, null,
            React.createElement(CopyButton, { items: items, selectedApp: selectedApp, getLink: getLink }),
            CloseButton)));
}
