import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * AutopipeVessel SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgFiletypeAutopipeVessel(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M11,3V0H2V16H14V3Z", fill: "#5ebb47" }),
        React.createElement("path", { d: "M11,0l3,3H11Z", fill: "#448136" }),
        React.createElement("path", { d: "M7.707,5.71261A1.52591,1.52591,0,0,0,9.2017,5.7015v.41114H9.732V4.131H9.2017v.41319A1.41845,1.41845,0,0,0,7.707,4.53381V4.131H7.17676v1.9816H7.707Z", fill: "#fff" }),
        React.createElement("path", { d: "M11.54465,4.70416h-.94683V4.131h-.53028v1.9816h.53028V5.57331h.32809c.38312,0,.485.0415.485.485v3.2918c0,.37153-.04915.485-.485.485H9.54921V9.27333H9.01892v1.9816h.53029v-.55071h1.99544a.70056.70056,0,0,0,.70054-.70059V5.40484A.70059.70059,0,0,0,11.54465,4.70416Z", fill: "#fff" }),
        React.createElement("path", { d: "M8.14243,9.83516H7.26715V9.05693s-.15366-.50229-1.02106-.62987V8.11193h.55627V7.58164H4.82076v.53029H5.377v.31513c-.8674.12759-1.02106.62987-1.02106.62987v2.26763l-.60116.475V14h.67747l-.003-1.90431a1.54986,1.54986,0,0,0,1.37311.5224h.01831a1.56228,1.56228,0,0,0,1.37818-.5224V14h.68256V11.77961l-.61431-.455v-.62034h.87528v.55071h.53028V9.27333H8.14243Z", fill: "#fff" }),
        React.createElement("rect", { x: "4.82076", y: "6.89794", width: "1.9816", height: "0.53028", fill: "#fff" }),
        React.createElement("path", { d: "M4.85437,5.56806H6.26392v.54458h.53029V4.131H6.26392v.568H5.47311c-.4128,0-.485-.08983-.485-.485v-.7624l-.83429,0V4.86747A.70057.70057,0,0,0,4.85437,5.56806Z", fill: "#fff" })));
}
