import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * WindowsExplorer SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgWindowsExplorer(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M14.5,1H1.5A1.50164,1.50164,0,0,0,0,2.5v11A1.50164,1.50164,0,0,0,1.5,15h13A1.50164,1.50164,0,0,0,16,13.5V2.5A1.50164,1.50164,0,0,0,14.5,1ZM1.5,2h13a.50065.50065,0,0,1,.5.5V4H1V2.5A.50034.50034,0,0,1,1.5,2Zm0,12a.50065.50065,0,0,1-.5-.5V5H5v9Zm13,0H6V5h9v8.5A.501.501,0,0,1,14.5,14Z" }),
        React.createElement("rect", { x: "2", y: "6", width: "2", height: "1" }),
        React.createElement("rect", { x: "2", y: "8", width: "2", height: "1" }),
        React.createElement("circle", { cx: "9.75269", cy: "3.06647", r: "0.5" }),
        React.createElement("circle", { cx: "11.75269", cy: "3.06647", r: "0.5" }),
        React.createElement("circle", { cx: "13.75269", cy: "3.06647", r: "0.5" })));
}
