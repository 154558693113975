import { query } from '../http';
import { mergeHeadersIntoRequestOptions } from '../http/headers';
import { assertValidInstanceId } from '../validate';
import { buildUploadHeaders } from './createDocument';
import { handleDocumentCreationError, handleFileUploadError } from './createDocumentWithFile';
import { replaceFile } from './uploadFile';
/**
 * Replaces file attached to a document
 * @param replaceDocumentFileOptions Options for replacing a file
 * @param replaceDocumentFileOptions.file File to upload
 * @param replaceDocumentFileOptions.documentOptions Information on the document to replace
 * @param replaceDocumentFileOptions.httpService Configured http service for the datasource
 * @param replaceDocumentFileOptions.requestOptions Options for the http request
 * @param replaceDocumentFileOptions.responseCallback Callback for tracking upload progress
 * @returns The response from the file upload; will throw original HTTP response if not ok
 */
export async function replaceDocumentFile(replaceDocumentFileOptions) {
    const documentResponse = await replaceDocument(replaceDocumentFileOptions);
    handleDocumentCreationError(documentResponse);
    if (documentResponse.status != 308) {
        return documentResponse;
    }
    const etag = documentResponse.headers.get('etag');
    if (!etag) {
        throw new Error('Etag not found');
    }
    const fileUploadResponse = await replaceFile(replaceDocumentFileOptions, etag);
    handleFileUploadError(fileUploadResponse);
    return fileUploadResponse;
}
async function replaceDocument({ documentOptions, file, httpService, requestOptions }) {
    const url = buildFileReplaceUrl(documentOptions);
    const headers = buildUploadHeaders(file, documentOptions);
    const replaceRequestOptions = mergeHeadersIntoRequestOptions(requestOptions, headers);
    const documentResponse = await httpService.put(url, null, replaceRequestOptions);
    return documentResponse;
}
function buildFileReplaceUrl(documentOptions) {
    var _a;
    assertValidInstanceId(documentOptions.instanceId);
    const customQueryParameters = documentOptions.className == 'LogicalSet'
        ? { leaveReferences: true }
        : undefined;
    const url = query({
        schemaName: 'PW_WSG',
        className: (_a = documentOptions.className) !== null && _a !== void 0 ? _a : 'Document',
        instanceId: documentOptions.instanceId,
        file: true,
        customQueryParameters: customQueryParameters
    });
    return url;
}
