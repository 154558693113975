import { useCallback, useEffect, useState } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { productionEnvironment } from '../buddi';
import { lookupInstrumentationKey } from './instrumentationKey';
/**
 * Initializes App Insights and sets user context.
 * @param AppInsightsConfig Configuration for App Insights
 * @param AppInsightsConfig.buddiRegionCode Region code, if not using instrumentation key
 * @param AppInsightsConfig.dataAccessLevel Optional data access level, if not using instrumentation key
 * @param AppInsightsConfig.instrumentationKey App Insights instrumentation key, if not using region code
 * @param AppInsightsConfig.user Valid OIDC user or function to return OIDC user
 * @returns Configured App Insights instance
 */
export function useAppInsights({ buddiRegionCode, dataAccessLevel, instrumentationKey, user }) {
    const [appInsights, setAppInsights] = useState();
    const buildAppInsights = useCallback(async () => {
        const key = instrumentationKey !== null && instrumentationKey !== void 0 ? instrumentationKey : (await lookupInstrumentationKey(buddiRegionCode, dataAccessLevel));
        const disableTelemetry = productionEnvironment() == 'localhost';
        return new ApplicationInsights({
            config: {
                instrumentationKey: key,
                disableFetchTracking: false,
                disableTelemetry: disableTelemetry
            }
        });
    }, [buddiRegionCode, dataAccessLevel, instrumentationKey]);
    const resolveUserId = useCallback(async () => {
        if (typeof user == 'function') {
            const userId = await user();
            return userId === null || userId === void 0 ? void 0 : userId.profile.sub;
        }
        return user === null || user === void 0 ? void 0 : user.profile.sub;
    }, [user]);
    useEffect(() => {
        async function initialize() {
            const appInsights = await buildAppInsights();
            appInsights.loadAppInsights();
            const userId = await resolveUserId();
            if (userId) {
                appInsights.setAuthenticatedUserContext(userId);
            }
            setAppInsights(appInsights);
        }
        void initialize();
    }, [buildAppInsights, resolveUserId]);
    return appInsights;
}
