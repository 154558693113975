import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * SpatialRoot SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgSpatialRoot(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M9.07,0.07C3.8,-0.6,-0.6,3.8,0.07,9.07c0.47,3.53,3.33,6.47,6.87,6.87c5.2,0.67,9.67-3.73,9-7.93C15.47,3.4,12.6,0.53,9.07,0.07z M8.8,13.93l-0.87-1.53l-0.8,1.53c-2.67-0.4-4.73-2.47-5.13-5.13l1.53-0.87L2,7.13c0.33-2.67,2.47-4.73,5.13-5.13L8,3.6l0.8-1.53c2.67,0.33,4.73,2.47,5.13,5.13 l-1.6,0.87l1.6,0.8C13.6,11.47,11.47,13.6,8.8,13.93z M10.6,5.07l-1.6,0.73l-2.13,1c-0.07,0-0.07,0.07-0.13,0.13l-1,2.13L5,10.67 c-0.07,0.2,0.13,0.4,0.33,0.33l1.6-0.73l2.13-1c0.07,0,0.07-0.07,0.13-0.13l1-2.13l0.73-1.6C11.07,5.13,10.8,4.93,10.6,5.07z M8,8.73 c-0.4,0-0.73-0.33-0.73-0.73s0.33-0.73,0.73-0.73s0.73,0.33,0.73,0.73S8.4,8.73,8,8.73z" })));
}
