import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * DeliverablesManagement SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgDeliverablesManagement(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "m0.2 4.2l4.5 4.4 7.8-6.45z" }),
        React.createElement("path", { d: "m0 4.45l3.1 3.15-3.1 3.85z" }),
        React.createElement("path", { d: "m0.25 11.7l3.15-3.85 1.3 1.35 2.35-1.95 5.45 6.55z" }),
        React.createElement("path", { d: "m7.35 6.95l5.55 6.65v-11.3z" }),
        React.createElement("path", { d: "m13.3 2.35l2.7 1.75v8.15l-2.65 1.6z" })));
}
