import { query } from '../http';
const MAX_UPLOAD_CHUNK_SIZE = 4194304;
/**
 * Uploads a file to an existing document in the datasource; will call recursively until the entire file is uploaded
 * Use `createDocumentWithFile` to create and upload a file in one call
 * @param uploadFileOptions Options for uploading a file
 * @param etag Etag of the created document to upload to
 * @returns The response from the file upload
 */
export async function uploadFile(uploadFileOptions, etag) {
    return uploadFileSlices(uploadFileOptions, etag, 'post');
}
/**
 * Replaces a file in an existing document in the datasource; will call recursively until the entire file is uploaded
 * `replaceDocumentFile` will get the etag and call this function
 * @param uploadFileOptions Options for uploading a file
 * @param etag Etag of the document to upload to
 * @returns The response from the file upload
 */
export async function replaceFile(uploadFileOptions, etag) {
    return uploadFileSlices(uploadFileOptions, etag, 'put');
}
async function uploadFileSlices(uploadFileOptions, etag, uploadFunction, amountRead = 0) {
    const { file, responseCallback } = uploadFileOptions;
    const response = await uploadFileSlice(uploadFileOptions, etag, uploadFunction, amountRead);
    amountRead += MAX_UPLOAD_CHUNK_SIZE;
    if (amountRead < file.size && response.status == 308) {
        responseCallback === null || responseCallback === void 0 ? void 0 : responseCallback(amountRead, file.size);
        return uploadFileSlices(uploadFileOptions, etag, uploadFunction, amountRead);
    }
    responseCallback === null || responseCallback === void 0 ? void 0 : responseCallback(file.size, file.size);
    return response;
}
async function uploadFileSlice({ file, documentOptions, httpService, requestOptions }, etag, uploadFunction, amountRead) {
    const url = uploadFunction == 'put'
        ? buildReplaceFileUrl(documentOptions)
        : buildUploadFileUrl(documentOptions);
    const fileSlice = nextFileSlice(file, amountRead);
    const headers = buildUploadHeaders(file, fileSlice, amountRead, etag, documentOptions);
    const fileUploadRequestOptions = buildUploadRequestOptions(requestOptions, headers);
    if (uploadFunction === 'put') {
        return httpService.put(url, fileSlice, fileUploadRequestOptions);
    }
    return httpService.post(url, fileSlice, fileUploadRequestOptions);
}
function buildUploadFileUrl(documentOptions) {
    const url = query({
        schemaName: 'PW_WSG',
        className: 'Document',
        instanceId: documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions.instanceId
    });
    return url;
}
function buildReplaceFileUrl(documentOptions) {
    var _a;
    if (!(documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions.instanceId)) {
        throw new Error('InstanceId is required for replacing a file');
    }
    const customQueryParameters = documentOptions.className == 'LogicalSet'
        ? { leaveReferences: true }
        : undefined;
    const url = query({
        schemaName: 'PW_WSG',
        className: (_a = documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions.className) !== null && _a !== void 0 ? _a : 'Document',
        instanceId: documentOptions.instanceId,
        file: true,
        customQueryParameters: customQueryParameters
    });
    return url;
}
function nextFileSlice(file, start) {
    let end = start + MAX_UPLOAD_CHUNK_SIZE;
    if (end > file.size) {
        end = file.size;
    }
    return file.slice(start, end, file.type);
}
function buildUploadHeaders(file, fileSlice, start, etag, documentOptions) {
    var _a;
    const fileName = (_a = documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions.FileName) !== null && _a !== void 0 ? _a : file.name;
    const contentDisposition = `attachment; IsFileNameUrlEncoded=true; filename="${encodeURIComponent(fileName)}"`;
    const contentRange = `bytes ${start}-${start + fileSlice.size - 1}/${file.size}`;
    const headers = {
        'Content-Disposition': contentDisposition,
        'Content-Range': contentRange,
        'If-Match': etag
    };
    return headers;
}
function buildUploadRequestOptions(requestOptions, headers) {
    const requestOptionsWithUploadHeaders = {
        ...requestOptions,
        headers: { ...requestOptions === null || requestOptions === void 0 ? void 0 : requestOptions.headers, ...headers }
    };
    return requestOptionsWithUploadHeaders;
}
