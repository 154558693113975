import { useCallback, useEffect, useMemo } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { AuthorizationService, HttpService, parseResponseRelationshipInstances } from '@bentley/pw-api';
import { useBuddi } from '../buddi';
/**
 * Fetches user's RBAC permissions for the given contextId.
 * @param rbacConfig Configuration for RBAC permissions
 * @param rbacConfig.buddiRegionCode Region code for BUDDI
 * @param rbacConfig.contextId Context ID for RBAC permissions
 * @param rbacConfig.getOidcToken Async function to get OIDC token
 * @returns RbacPermission[]; empty array if not initialized or error
 */
export function useRbac({ buddiRegionCode, contextId, getOidcToken }) {
    const [permissions, setPermissions] = useSessionStorage(`rbac${contextId ? `:${contextId}` : ''}`, []);
    const pwWebUrl = useBuddi('ProjectWiseWAC.URL', buddiRegionCode);
    const httpService = useMemo(() => {
        if (!pwWebUrl) {
            return undefined;
        }
        return new HttpService({
            authorization: new AuthorizationService({ getOidcToken }),
            baseUrl: pwWebUrl
        });
    }, [getOidcToken, pwWebUrl]);
    const getRbacPermissions = useCallback(async (httpService, contextId) => {
        try {
            const urlParams = new URLSearchParams({ contextId });
            const url = `api/rbac?${urlParams.toString()}`;
            const response = await httpService.get(url);
            if (!response.ok || response.status !== 200) {
                throw response;
            }
            const permissions = await parseResponseRelationshipInstances(response);
            return permissions;
        }
        catch (e) {
            console.error('Error fetching RBAC permissions', e);
            return [];
        }
    }, []);
    useEffect(() => {
        async function getRbac() {
            if (!httpService || !contextId || permissions.length) {
                return;
            }
            const rbacPermissions = await getRbacPermissions(httpService, contextId);
            setPermissions(rbacPermissions);
        }
        void getRbac();
    }, [
        contextId,
        permissions.length,
        getRbacPermissions,
        setPermissions,
        httpService
    ]);
    return permissions;
}
