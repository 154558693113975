import { CacheService, HttpService } from '@bentley/pw-api';
/**
 * Fetches registered BUDDI regions.
 * @returns Array of BUDDI regions, empty array if error
 */
export async function getBuddiRegions() {
    const httpService = new HttpService({
        baseUrl: 'https://buddi.bentley.com/WebService',
        cache: new CacheService({ name: 'BuddiRegions' })
    });
    try {
        const response = await httpService.get('GetRegions');
        if (!response.ok) {
            throw response;
        }
        const regions = (await response.json());
        return regions.result;
    }
    catch (e) {
        console.error('Error getting BUDDI regions', e);
        return [];
    }
}
/**
 * Fetches the environment of a BUDDI region by querying the BUDDI regions.
 * @param regionCode BUDDI region code
 * @returns Environment of the region, 'Production' if not found
 */
export async function getBuddiRegionEnvironment(regionCode) {
    var _a, _b;
    const buddiRegions = await getBuddiRegions();
    const environment = (_b = (_a = buddiRegions.find((region) => String(region.id) == regionCode)) === null || _a === void 0 ? void 0 : _a.environment) !== null && _b !== void 0 ? _b : 'Production';
    return environment;
}
