import React from 'react';
import { Svg } from '../svgWrapper/svg';
/**
 * RamStructural SVG icon component.
 * @param props SVG props
 * @returns JSX.Element
 */
export function SvgFiletypeRamStructural(props) {
    return (React.createElement(Svg, { ...props },
        React.createElement("path", { d: "M10.98363,3V0h-9V16h12V3Z", fill: "#5ebb47" }),
        React.createElement("path", { d: "M10.98363,0l3,3h-3Z", fill: "#448136" }),
        React.createElement("path", { d: "M4.52491,12.95449A12.5896,12.5896,0,0,1,6.31908,12.324c.06765-.0183.136-.0354.20436-.05265a13.43884,13.43884,0,0,1,1.8224-.32963c.05894-.00648.11737-.01137.17609-.017q.14333-.01394.28545-.02469a11.922,11.922,0,0,1,2.84077.11457,9.34938,9.34938,0,0,1,1.60708.41029,27.37958,27.37958,0,0,0-4.33352.14282A27.534,27.534,0,0,0,2.712,13.96345,10.7458,10.7458,0,0,1,4.52491,12.95449ZM6.93458,5.97392,9.43,6.7907V6.33327L6.93458,5.5165c-.92086-.26147-1.27745.03266-1.33684.392v.52278C5.65713,6.23528,5.80578,5.61439,6.93458,5.97392Zm0,3.62687L9.43,10.41761V9.96018L6.93458,9.14339c-.92086-.2615-1.27745.03268-1.33684.39208v.52271C5.65713,9.86216,5.80578,9.24132,6.93458,9.60079Zm0-.90676L9.43,9.51086v-.4574L6.93458,8.23667c-.92086-.2615-1.27745.03267-1.33684.39207v.52271C5.65713,8.9554,5.80578,8.33463,6.93458,8.694Zm0-.90672L9.43,8.60413V8.14677L6.93458,7.33c-.92086-.26147-1.27745.03266-1.33684.392v.52274C5.65713,8.04874,5.80578,7.42786,6.93458,7.78731Zm0-.90668L9.43,7.69746V7.24L6.93458,6.42322c-.92086-.26145-1.27745.03268-1.33684.39208V7.338C5.65713,7.142,5.80578,6.52117,6.93458,6.88063Zm-.738,5.2188c.06765-.0183.13595-.0354.20436-.05265a13.43729,13.43729,0,0,1,1.8224-.32963c.05894-.00648.11737-.01137.17608-.017q.14334-.01394.28546-.02469a12.39771,12.39771,0,0,1,1.92037.00281,1.01851,1.01851,0,0,0,.00576-.10639l-.1997-.04416V5.3324s-.63779-.20381-1.47045-.55974c-.83275-.35591-.847.33019-.847.33019v.11L6.93458,4.83344c-.92086-.26145-1.27745.03266-1.33684.3921v.52274c.05939-.19607.208-.81692,1.33684-.45743l1.15927.37944v.00538l.5963.1898L9.43,6.10764V6.101l.35734.11377v4.94853l-2.85279-.88155c-.9358-.196-1.27745.03267-1.33684.39214v.28826l-.22272.1033c0,.43182-.02843.86715-.02878,1.29616C5.6233,12.26594,5.9065,12.17766,6.19657,12.09943Z", fill: "#fff" })));
}
