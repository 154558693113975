import { itemIsDataItem, itemIsFileType } from './itemAssertions';
/**
 * Checks IsLatest and Sequence properties on DataItems to determine if item is the latest in a series of versions
 * @param item Item to check
 * @returns `true` if item has older versions
 */
export function itemHasOlderVersions(item) {
    if (!itemIsDataItem(item)) {
        return false;
    }
    return item.IsLatest && item.Sequence > 0;
}
/**
 * Checks IsFinal property on DataItems to determine if item is final
 * @param item Item to check
 * @returns `true` if item is final
 */
export function itemIsFinal(item) {
    if (!itemIsDataItem(item)) {
        return false;
    }
    return item.IsFinal;
}
/**
 * Checks IsReadOnly, IsLocked, IsCheckedOut, and IsFinal properties on FileTypes to determine if item is immutable
 * @param item Item to check
 * @returns `true` if item is immutable
 */
export function itemIsImmutable(item) {
    if (!itemIsFileType(item)) {
        return false;
    }
    return (itemIsReadOnly(item) || itemIsLockedOrCheckedOut(item) || itemIsFinal(item));
}
/**
 * Checks IsLatest property on DataItems to determine if item is the latest version
 * @param item Item to check
 * @returns `true` if item is the latest version
 */
export function itemIsLatestVersion(item) {
    if (!itemIsDataItem(item)) {
        return false;
    }
    return item.IsLatest;
}
/**
 * Checks IsLocked or IsCheckedOut properties on non-placeholder DataItems
 * @param item Item to check
 * @returns `true` if item is locked or checked out
 */
export function itemIsLockedOrCheckedOut(item) {
    if (!itemIsDataItem(item)) {
        return false;
    }
    if (itemIsPlaceholder(item)) {
        return false;
    }
    return item.IsLocked || item.IsCheckedOut;
}
/**
 * Checks IsLocked and IsCheckedOut properties on DataItems to determine if item is locked to another user
 * @param item Item to check
 * @returns `true` if item is locked to another user
 */
export function itemIsLockedToOtherUser(item) {
    if (!itemIsDataItem(item)) {
        return false;
    }
    if (itemIsPlaceholder(item)) {
        return false;
    }
    return item.IsLocked && !item.IsCheckedOut;
}
/**
 * Checks FileName property on FileTypes to determine if item is a placeholder (empty document without a file)
 * @param item Item to check
 * @returns `true` if item is a placeholder
 */
export function itemIsPlaceholder(item) {
    if (!itemIsFileType(item)) {
        return false;
    }
    return !item.FileName;
}
/**
 * Checks IsReadOnly property on DataItems
 * @param item Item to check
 * @returns `true` if item has read only status
 */
export function itemIsReadOnly(item) {
    if (!itemIsDataItem(item)) {
        return false;
    }
    return item.IsReadOnly;
}
