import { itemIsFlatSet } from '@bentley/pw-api';
import { datasourcePrefix } from '../../constants';
export const sharedProjectParam = 'project';
export const sharedSetParam = 'set';
export const sharedItemParam = 'item';
export const sharedItemType = 'type';
export const sharedDatasourceId = 'datasource';
export const sharedWorkArea = 'workArea';
const linkConstants = {
    datasource: 'ds',
    objectType: 't',
    objectId: 'id',
    workAreaId: 'wa',
    projectId: 'p'
};
export function getShareableLink(connection, itemId, itemType) {
    if (!connection.DatasourceInstanceId) {
        throw 'Datasource ID missing';
    }
    if (!itemId) {
        throw 'Item ID missing';
    }
    if (!itemType) {
        throw 'Item type missing';
    }
    const datasourceQuery = `${sharedDatasourceId}=${connection.DatasourceInstanceId}`;
    const workAreaQuery = connection.ProjectId
        ? `&${sharedWorkArea}=${connection.ProjectId}`
        : '';
    const itemQuery = `${sharedItemParam}=${itemId}`;
    const itemTypeQuery = `${sharedItemType}=${itemType}`;
    const pathWithoutConnection = location.pathname.substring(0, location.pathname.indexOf('/connection'));
    return `${location.origin}${pathWithoutConnection}?${datasourceQuery}&${itemQuery}&${itemTypeQuery}${workAreaQuery}`;
}
export function getUniversalLink({ datasource, workAreaId, objectId, objectType, projectId, application, apiURL }) {
    // Remove 'Bentley.PW--' from datasource name
    if (datasource.startsWith(datasourcePrefix)) {
        datasource = datasource.substring(datasourcePrefix.length);
    }
    // Remove hyphens from GUIDs
    workAreaId = workAreaId === null || workAreaId === void 0 ? void 0 : workAreaId.replace(/-/g, '');
    objectId = objectId.replace(/-/g, '');
    projectId = projectId === null || projectId === void 0 ? void 0 : projectId.replace(/-/g, '');
    // Shorten link constants' names and combine objectType and objectId params
    const datasourceQuery = `${linkConstants.datasource}=${datasource}`;
    const workAreaQuery = workAreaId
        ? `&${linkConstants.workAreaId}=${workAreaId}`
        : '';
    const itemQuery = `${objectType == 'folder' ? 'fld' : objectType}=${objectId}`;
    const projectQuery = projectId
        ? `&${linkConstants.projectId}=${projectId}`
        : '';
    apiURL = apiURL ? apiURL : location.origin;
    if (!apiURL.endsWith('/')) {
        apiURL = `${apiURL}/`;
    }
    if (application == 'WebView') {
        return `${apiURL}link?${datasourceQuery}&${itemQuery}${getApplicationQuery(application)}`;
    }
    else {
        const correctedDatasourceQuery = application == 'Explorer'
            ? datasourceQuery.replace(/~20/g, '%20')
            : datasourceQuery;
        return `${apiURL}link?${correctedDatasourceQuery}&${itemQuery}${workAreaQuery}${projectQuery}${getApplicationQuery(application)}`;
    }
}
export function getItemType(itemClass) {
    if (itemClass == 'Project') {
        return 'folder';
    }
    return 'doc';
}
function getApplicationQuery(application) {
    const param = '&app=';
    if (application == 'Explorer') {
        return param + 'pwe';
    }
    if (application == 'Web') {
        return param + 'web';
    }
    if (application == 'WebView') {
        return param + 'webview';
    }
    return '';
}
export function getLocation(connection, parent) {
    if (!parent || !parent.instanceId) {
        return connection.Id;
    }
    const parentType = itemIsFlatSet(parent)
        ? sharedSetParam
        : sharedProjectParam;
    return `connection/${connection.Id}?${parentType}=${parent.instanceId}`;
}
export function getEncodedContext(datasource, objectGuid, objectType, wacId) {
    const context = `${datasource}/${objectGuid}/${objectType}/${wacId}`;
    return base64EncodeUrl(window.btoa(context));
}
// To handle string like "QmVudGxleS5QVy0tUFdXZWJXQUNWTTEuYmVudGxleS5jb21+M0FSYW1ib2xsLzRlOTAwZWIzLTlmNTUtNGFiNS1iOWVkLWI2ZTJjMTAwOWIwNy9mb2xkZXIvYjllMzc4YjAtMzEwOC00ZTAwLWJhMjktNDRjNjFhYWQ0NDkw"
function base64EncodeUrl(str) {
    return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
